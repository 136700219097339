export default {
  translation: {
    all: {
      invite_msg: 'Add your friends and get 5 generations free.',
      email: 'Email',
      feed: 'Feed',
      welcome: 'Welcome',
      upload: 'Upload',
      nav_popup_msg: 'Click to Toggle Navigation On/Off',
      buy_plan: 'Buy Plans',
      profile: 'Profile',
      search: 'Search',
      create: 'Create',
      feeds: 'Feeds',
      image_edit: 'Image Edit',
      generate_edited_image: 'Generate Edited Image',
      edit_image: 'Edit Image',
      cancel: 'Cancel',
      failed_get_user: 'Failed to get user',
      failed_get_image: 'Failed to get image',
      you_already_liked_this_image: 'You already liked this image.',
      failed_to_set_like: 'Failed to set Like',
      invalid_password: 'Invalid Password',
      email_already_registered:
        'Email already registered. Please try anothe email.',
      failed_to_reset_password: 'Failed to reset password',
      failed_to_get_user_profile: 'Failed to get user profile',
      failed_to_subscribe_yearly: 'Failed to subscribe yearly',
      failed_to_subscribe_monthly: 'Failed to subscribe monthly',
      failed_to_subscribe_weekly: 'Failed to subscribe weekly',
      failed_to_unsubscribe: 'Failed to unsubscribe',
      failed_to_purchase_tokens: 'Failed to purchase Tokens',
      failed_to_remove_one_token: 'Failed to remove one token.',
      failed_to_get_all_categories: 'Failed to get all categories',
      failed_to_get_all_posts_on_search_page:
        'Failed to get all posts on Search page',
      failed_to_get_posts: 'Failed to get posts',
      failed_to_get_all_posts_on_profile_page:
        'Failed to get all posts on profile page',
      please_input_email_address: 'Please input email address.',
      error_occurred_during_sending_message_via_nodemailer:
        'Error occurred during sending message via nodemailer',
      please_input_code: 'Please input code.',
      error_occurred_during_verify_code: 'Error occurred during verify code',
      success_to_reset_password: 'Success to reset password',
      error_occurred_during_reset_password:
        'Error occurred during reset password',
      please_input_name: 'Please input name',
      please_input_user_name: 'Please input user name',
      please_input_email: 'Please input email',
      invalidate_email: 'Invalidate Email, Please try again.',
      please_input_password: 'Please input password',
      please_input_country: 'Please input country',
      please_input_last_name: 'Please input Last Name',
      failed_to_get_user_profile_by_id: 'Failed to get user profile by id',
      failed_to_get_one_post_by_id: 'Failed to get one post by id',
      failed_to_get_all_posts_on_artwork: 'Failed to get all posts on artwork',
      there_is_no_permission_to_edit_this_image:
        'There is no permission to edit this image.',
      failed_to_add_new_comment: 'Failed to add new comment.',
      failed_to_delete_post: 'Failed to delete post.',
      failed_to_get_post_on_comment_screen:
        'Failed to get post on Comment screen',
      saved_image: 'Saved image.',
      failed_to_get_openai_images: 'Failed to get openai images',
      please_input_prompt: 'Please input prompt.',
      please_do_not_use_this_word: "Please don't use this word",
      confirm_your_selection: 'Confirm your selection.',
      please_select_image: 'Please select image.',
      saved_selected_images: 'Saved selected images.',
      for_all_editing_capabilities:
        'For all editing capabilities, Please get tokens/subscription.',
      please_select_category: 'Please select category.',
      failed_to_send_invite: 'Failed to send invite',
      failed_to_post: 'Failed to post',
      failed_to_get_users: 'Failed to get users',
      failed_to_save_image: 'Failed to save image',
      already_added_user: 'Already added user',
      failed_to_get_user_by_id_on_support:
        'Failed to get user by id on support',
      success_to_add_supporter: 'Success to add supporter.',
      failed_to_set_supporter: 'Failed to set supporter',
      failed_to_delete_account: 'Failed to delete account',
      please_input_customer_name_for_payment:
        'please input customer name for payment.',
      your_order_is_confirmed: 'Your order is confirmed!',
      your_order_is_unsubscribed: 'Your order is unsubscribed!',
      please_select_currency: 'Please select Currency.',
      please_select_language: 'Please select language.',
      please_input_fields: 'Please input fields.',
      please_mismatch_password: 'Please mismatch password.',
      please_input_your_name: 'Please input your name.',
      please_input_subject: 'Please input subject.',
      please_input_message: 'Please input message.',
      success_to_sent_message: 'Success to sent message.',
      failed_to_sent_message: 'Failed to sent message.',
      failed_to_get_user: 'Failed to get user',
      creations_for_you: 'Creations, For you. By you.',
      global_generative_ai_Platform: 'Global Generative AI Platform',
      get_started: 'Get Started',
      try_pink_elephant:
        'Try.. Pink Elephant dancing on a ball in the mountain.',
      artwork: 'Artwork',
      category: 'Category',
      discover: 'Discover',
      search_users: 'Search Users, Pictures...',
      posts: 'Posts',
      supporters: 'Supporters',
      supporting: 'Supporting',
      bio: 'Bio',
      gallery: 'Gallery',
      collaborations: 'Collaborations',
      settings: 'Settings',
      tokens_left: 'TOKENS LEFT',
      account_settings: 'Account Settings',
      privacy_policy: 'Privacy policy',
      terms_of_use: 'Terms of Use',
      leaderboard: 'Leaderboard',
      contact_us: 'Contact Us',
      logout: 'Logout',
      edit_profile: 'Edit Profile',
      change_password: 'Change Password',
      languages: 'Languages',
      currency: 'Currency',
      delete_account: 'Delete Account',
      tip_msg:
        'When you delete your account all your data and tools of mindfulness will be deleted',
      are_you_sure_you_want_to_delete_your_account:
        'Are you sure You want to delete your account?',
      name: 'Name',
      user_name: 'User name',
      username: 'User name',
      save_changes: 'Save changes',
      current_password: 'Current password',
      new_password: 'New password',
      confirm_new_password: 'Confirm new password',
      save_new_password: 'Save new Password',
      choose_languages: 'Choose Languages',
      select_option: 'Select option',
      save_language: 'Save Language',
      choose_currency: 'Choose Currency',
      select_currency: 'Select Currency',
      subject: 'Subject',
      message: 'Message',
      send: 'Send',
      press_and_speak_your_prompt: 'Press and speak your prompt!',
      edit_your_photo_further: 'Edit Your Photo Further! Also Collaborate!',
      image_saves_to_your_gallery_when_you_post:
        'Image saves to your gallery when you post!',
      make_more_from_your_words_and_search_for_users_and_other_images:
        'Make more from your words and Search for users and other images!',
      upload_photos_from_your_gallery_and_edit_them:
        'Upload photos from your gallery and edit them',
      check_your_notifications_here: 'Check your Notifications here',
      press_to_support_and_collaborate: 'Press to support and Collaborate',
      subscriptions: 'Subscriptions',
      tokens: 'Tokens',
      view_details: 'View Details',
      monthly: 'Monthly',
      Weekly: 'Weekly',
      Yearly: 'Yearly',
      most_popular: 'Most Popular',
      upgrade_plan: 'Upgrade Plan',
      buy_now: 'Buy Now',
      plan_details: 'Plan Details',
      image_generator: 'Image Generator',
      unlimited_editing_tools: 'Unlimited Editing Tools',
      full_collaboration_capabilities: 'Full collaboration Capabilities',
      title: 'Title',
      created_at: 'Created at',
      comment: 'Comment',
      share_image: 'Share Image',
      share_image_facebook: 'Share image facebook, Instagram, Pinterest',
      download_image: 'Download image',
      related_artwork: 'Related Artwork',
      no_creations_yet: 'No creations yet',
      turn_off_commenting: 'Turn off commenting',
      invite_collaborations: 'Invite Collaborations',
      categories: 'Categories',
      hide_prompt: 'Hide prompt',
      err_phone_msg:
        "error:  please input your friend's email or phone number.",
      phone_number: 'Phone Number',
      buy_more:
        'Buy more tokens/ Get subscription To Continue Generating Images',
      ok: 'OK',
      generated: 'Generated',
      view_variation: 'View Variations',
      variations: 'Variations',
      variation_msg:
        'You can select from these variations or can select the previous generation',
      new_category: 'New Category',
      collaborate: 'Collaborate',
      place_search_user: 'Search Users',
      new_post: 'New Post',
      write_caption: 'Write Caption...',
      peoples: 'Peoples',
      access_collaboration:
        'Access Collaboration By Supporting Others And Having Them Support You!',
      artworks: 'Artworks',
      users: 'Users',
      notifications: 'Notifications',
      success: 'Success',
      error_code: 'Error code',
      unsubscribe: 'Unsubscribe',
      connecting_appstore:
        'Connecting appstore, Please wait a sec and try again',
      purchased: 'Purchased',
      save: 'Save',
      only: 'Only',
      your_name: 'Your Name',
      liked_images: 'Liked Images',
      support: 'Support',
      weekly_plan_details: 'Weekly Plan Details',
      monthly_plan_details: 'Monthly Plan Details',
      yearly_plan_details: 'Yearly Plan Details',
      logo_tip: 'Tip: Use AIKO Logo Button on bottom of screen to navigate.',
      got_it: 'ok. got it',
      instructions: 'INSTRUCTIONS',
      no_bullying: 'NO Bullying / Harassment',
      no_nudity: 'NO Nudity/ Sexual Content',
      young_visitors: '(We have young visitors)',
      no_political: 'NO Political Campaigns',
      only_love: 'Only Love, Creation, and Collaboration',
      learn_more: 'LEARN MORE',
      login: 'Login',
      welcome_back: 'Welcome Back',
      new_to_aiko: 'New to AIKO?',
      sign_up: 'Sign up',
      continue: 'Continue',
      forgot_passwword: 'Forgot Passwword',
      reset_password: 'Reset password link will be sent to your email',
      sent_verification_code: "We've Sent Verification Code To",
      enter_code: 'Please Enter Code',
      enter_password: 'Enter Password',
      enter_confirm_password: 'Enter Confirm Password',
      register: 'Register',
      hello: 'Hello there, lets know each other',
      password: 'Password',
      receive_invite: 'Did you receive invite from your friend?',
      optional: 'Optional',
      delete: 'Delete',
      comments: 'Comments',
      buy_plans: 'Buy Plans',
      country: 'Country',
      more: 'More',
      chat: 'Chat',
      chatDetail: 'Private Chat',
      commingSoon: 'Coming Soon !!!',
      stories: 'Stories',
      success_to_add_story: 'Success to add story',
      saved_to_gallery: 'Saved to Gallery',
      add_story: 'Add Story',
      save_to_gallery: 'Save to Gallery',
      me: 'Me',
      viking_helmet: 'Viking Helmet',
      stallone: 'Stallone',
      ping_pong: 'Ping Pong',
      humanoid: 'Humanoid',
      fire_effect: 'Fire Effect',
      emotion_meter: 'Emotion Meter',
      emotions_exaggerator: 'Emotion Exaggerator',
      look1: 'Look1',
      look2: 'Look2',
      aviators: 'Aviators',
      ball_face: 'Ball face',
      beard: 'Beard',
      beauty: 'Beauty',
      fairy_lights: 'Fairy Lights',
      background_segmentation: 'Background Segmentation',
      hair_segmentation: 'Hair Segmentation',
      flower_crown: 'Flower Crown',
      frankenstein: 'Frankenstein',
      lion: 'Lion',
      manly_face: 'Manly Face',
      plastic_ocean: 'Plastic Ocean',
      pumpkin: 'Pumpkin',
      scuba: 'Scuba Diver',
      tape_face: 'Tape Face',
      tiny_sunglasses: 'Tiny Sunglasses',
      topology: 'Topology',
      photo: 'Photo',
      video: 'Video',
      create_prompt_or_upload: 'Create Prompt or Upload',
      or: 'or',
      delete_story: 'Delete Story',
      failed_to_get_stories: 'Failed to get stories',
      success_to_delete_story: 'Success to delete story',
      create_background_image_with_prompt:
        'Create Background Image with Prompt',
      prompt: 'prompt',
      failed_to_get_all_supporters_by_user_id:
        'Failed to get all supporters by user id',
      failed_to_get_all_supportings_by_user_id:
        'Failed to get all supportings by user id',
      failed_to_search_users: 'Failed to search users',
      failed_to_search_posts: 'Failed to search posts',
      share_as_post: 'Share as post',
      add_mentions: 'Add Mentions',
      sell_your_work: 'Sell Your Work',
      goods: 'Goods',
      price_msg: 'Please add price for this image',
      err_price_msg: 'error: please input price',
      buy: 'Buy',
      register_card: 'Register Card',
      input_card_information: 'Please input card information correctly.',
      register_card_info: 'Register Card Information',
      ar_err: 'Will be available soon',
      // 'AR currently supports till iOS 17.0. AR is currently being updated for iOS 17.0 and will be available soon',
      create_story: 'Create Story',
      use_ar: 'Use AR',
      use_ai_video: 'Use AI Video',
      for_story:
        'To create Story, You can use AR or AI Video Generator. Please choose one.',
      create_ai_video: 'Create AI Video',
      failed_to_generate_Video: 'Failed to generate Video',
      use_ai_video_audio: 'Use AI Dance',
      create_ai_video_audio: 'Create Video From Audio',
      select_audio: 'Select Audio Files',
      select_audio_desc: 'You can select up to 10 audio files.',
      input_prompt: 'Please input prompt to create video',
      generating_video: 'Generating Videos...',
      generated_video_msg:
        'Generated videos added on your stories. Please check videos on stories page.',
      create_more_files: "You can't generate 10 more videos",
      video_audio_popup:
        'Tip: Go do your thing! We’ll notify you when your favorite music videos have generated!',
      create_after_msg:
        'Go do your thing! We’ll notify you when your favorite music videos have generated!',
      ai_audio_video_prompt_tip:
        'A painting of a moth, A painting of a killer dragonfly, Two fishes talking to each other in deep sea, A painting of a moth',
      audio_create_video: 'Create Video',
      audio_trimming: 'Clip Audio',
      choose_clip_duration: 'Choose Clip Duration',
      generator_video: 'Generator Video',
      second: 'Seconds',
      spotify_home: 'Spotify Home',
      your_playlist: 'Your Playlists',
      add_to_playlist: 'Add to playlist',
      expire_token: 'The access token expired',
      search_music: 'Search Music',
      music_name: 'Music Name',
      status: 'Status',
      completed: 'Completed',
      progressing: 'Progressing',
      prompt1: 'Prompt',
      music: 'Music',
      your_items: 'Your Items',
      alert_uncomplete_video:
        'Video is generating, Please check after generated.',
      success_to_update_operation: 'Added to stories',
      success_to_delete_operation: 'Deleted video',
      add_to_story: 'Add to story',
      are_you_sure_you_want_to_delete_this_audio:
        'Are you sure You want to delete this audio?',
      delete_video: 'Delete Video',
      limit_downloading_cnt:
        'You cannot download more than 3 files at the same time.',
      musics: 'Musics',
      are_you_sure_want_to_delete : 'Are You Sure! Want to Delete',
      database :  'Database',
      no_keep_it :  'No, Keep It',
      yes_delete_it : 'Yes, Delete It',
      is_invite : 'Is inviting you to be a collaborator on their post?',
      reject: 'Reject',
      accept: 'Accept',
      my_profile : 'My Profile',
      stripe_payment : 'Stripe Payment',
      please_input_all_informations : 'Please input all informations.',
      there_is_no_token : 'There is no left token. Please purchase Tokens.',
      edit: 'Edit',
      add: 'Add',
      profile_picture : 'Profile Picture',
      status_complete:  'Status : Completed',
      status_progressing :  'Status :  Progressing',
      story: 'Story',
      download: 'Download',
      Home: 'Home',
      Search: 'Search',
      Profile: 'Profile',
      Setting: 'Setting',
      ContactUs: 'ContactUs',
      HealthCheck: 'HealthCheck',
      log_out: 'Log out',
      dashboard: 'Dashboard',
      update_profile: 'Update Profile',
      birthday: 'Birthday',
      Chat: 'Chat',
    },
  },
};
