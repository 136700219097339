export default {
  translation: {
    all: {
      invite_msg: 'अपने दोस्तों को जोड़ें और 5 पीढ़ियों को मुफ़्त पाएं।',
      email: 'ईमेल',
      feed: 'खिलाना',
      welcome: 'स्वागत',
      upload: 'डालना',
      nav_popup_msg: 'नेविगेशन को चालू/बंद टॉगल करने के लिए क्लिक करें',
      buy_plan: 'योजनाएं खरीदें',
      profile: 'प्रोफ़ाइल',
      search: 'खोज',
      create: 'बनाएं',
      feeds: 'फ़ीड',
      image_edit: 'छवि संपादित करें',
      generate_edited_image: 'संपादित छवि उत्पन्न करें',
      edit_image: 'संपादित छवि',
      cancel: 'रद्द करना',
      failed_get_user: 'उपयोगकर्ता प्राप्त करने में विफल',
      failed_get_image: 'छवि प्राप्त करने में विफल',
      you_already_liked_this_image: 'आपको यह छवि पहले ही पसंद आ चुकी है.',
      failed_to_set_like: 'लाइक सेट करने में विफल',
      invalid_password: 'अवैध पासवर्ड',
      email_already_registered:
        'ईमेल पहले से ही पंजीकृत है। कृपया कोई अन्य ईमेल आज़माएँ.',
      failed_to_reset_password: 'पासवर्ड रीसेट करने में विफल',
      failed_to_get_user_profile: 'उपयोगकर्ता प्रोफ़ाइल प्राप्त करने में विफल',
      failed_to_subscribe_yearly: 'वार्षिक सदस्यता लेने में विफल',
      failed_to_subscribe_monthly: 'मासिक सदस्यता लेने में विफल',
      failed_to_subscribe_weekly: 'साप्ताहिक सदस्यता लेने में विफल',
      failed_to_unsubscribe: 'सदस्यता समाप्त करने में विफल',
      failed_to_purchase_tokens: 'टोकन खरीदने में विफल',
      failed_to_remove_one_token: 'एक टोकन हटाने में विफल.',
      failed_to_get_all_categories: 'सभी श्रेणियाँ प्राप्त करने में विफल',
      failed_to_get_all_posts_on_search_page:
        'खोज पृष्ठ पर सभी पोस्ट प्राप्त करने में विफल',
      failed_to_get_posts: 'पोस्ट प्राप्त करने में विफल',
      failed_to_get_all_posts_on_profile_page:
        'प्रोफ़ाइल पृष्ठ पर सभी पोस्ट प्राप्त करने में विफल',
      please_input_email_address: 'कृपया ईमेल पता दर्ज करें.',
      error_occurred_during_sending_message_via_nodemailer:
        'नोडमेलर के माध्यम से संदेश भेजने के दौरान त्रुटि उत्पन्न हुई',
      please_input_code: 'कृपया कोड इनपुट करें.',
      error_occurred_during_verify_code:
        'कोड सत्यापित करने के दौरान त्रुटि हुई',
      success_to_reset_password: 'पासवर्ड रीसेट करने में सफलता',
      error_occurred_during_reset_password: 'पासवर्ड रीसेट के दौरान त्रुटि हुई',
      please_input_name: 'कृपया नाम इनपुट करें',
      please_input_user_name: 'कृपया उपयोगकर्ता नाम इनपुट करें',
      please_input_email: 'कृपया ईमेल इनपुट करें',
      invalidate_email: 'अमान्य ईमेल, कृपया पुनः प्रयास करें।',
      please_input_password: 'कृपया पासवर्ड डालें',
      please_input_country: 'कृपया देश इनपुट करें',
      please_input_last_name: 'कृपया अंतिम नाम दर्ज करें',
      failed_to_get_user_profile_by_id:
        'आईडी द्वारा उपयोगकर्ता प्रोफ़ाइल प्राप्त करने में विफल',
      failed_to_get_one_post_by_id:
        'आईडी द्वारा एक पोस्ट प्राप्त करने में विफल',
      failed_to_get_all_posts_on_artwork:
        'कलाकृति पर सभी पोस्ट प्राप्त करने में विफल',
      there_is_no_permission_to_edit_this_image:
        'इस छवि को संपादित करने की कोई अनुमति नहीं है.',
      failed_to_add_new_comment: 'नई टिप्पणी जोड़ने में विफल.',
      failed_to_delete_post: 'पोस्ट हटाने में विफल.',
      failed_to_get_post_on_comment_screen:
        'टिप्पणी स्क्रीन पर पोस्ट प्राप्त करने में विफल',
      saved_image: 'छवि सहेजी गई.',
      failed_to_get_openai_images: 'openai छवियाँ प्राप्त करने में विफल',
      please_input_prompt: 'कृपया शीघ्र इनपुट करें.',
      please_do_not_use_this_word: 'कृपया इस शब्द का प्रयोग न करें',
      confirm_your_selection: 'अपने चयन की पुष्टि करें.',
      please_select_image: 'कृपया छवि चुनें.',
      saved_selected_images: 'चयनित छवियाँ सहेजी गईं।',
      for_all_editing_capabilities:
        'सभी संपादन क्षमताओं के लिए, कृपया टोकन/सदस्यता प्राप्त करें।',
      please_select_category: 'कृपया श्रेणी चुनें.',
      failed_to_send_invite: 'आमंत्रण भेजने में विफल',
      failed_to_post: 'पोस्ट करने में विफल',
      failed_to_get_users: 'उपयोगकर्ता प्राप्त करने में विफल',
      failed_to_save_image: 'छवि सहेजने में विफल',
      already_added_user: 'उपयोगकर्ता पहले ही जोड़ा जा चुका है',
      failed_to_get_user_by_id_on_support:
        'समर्थन पर आईडी द्वारा उपयोगकर्ता प्राप्त करने में विफल',
      success_to_add_supporter: 'समर्थक जोड़ने में सफलता.',
      failed_to_set_supporter: 'समर्थक सेट करने में विफल',
      failed_to_delete_account: 'खाता हटाने में विफल',
      please_input_customer_name_for_payment:
        'कृपया भुगतान के लिए ग्राहक का नाम दर्ज करें।',
      your_order_is_confirmed: 'आपके ऑर्डर की पुष्टि हो गई है!',
      your_order_is_unsubscribed: 'आपका ऑर्डर सदस्यता समाप्त कर दिया गया है!',
      please_select_currency: 'कृपया मुद्रा चुनें.',
      please_select_language: 'कृपया भाषा चुनें.',
      please_input_fields: 'कृपया फ़ील्ड इनपुट करें.',
      please_mismatch_password: 'कृपया पासवर्ड बेमेल करें.',
      please_input_your_name: 'कृपया अपना नाम दर्ज करें.',
      please_input_subject: 'कृपया विषय इनपुट करें.',
      please_input_message: 'कृपया संदेश इनपुट करें.',
      success_to_sent_message: 'संदेश भेजने में सफलता.',
      failed_to_sent_message: 'संदेश भेजने में विफल.',
      failed_to_get_user: 'उपयोगकर्ता प्राप्त करने में विफल',
      creations_for_you: 'रचनाएँ, आपके लिए। आपके द्वारा।',
      global_generative_ai_Platform: 'ग्लोबल जेनरेटिव एआई प्लेटफॉर्म',
      get_started: 'शुरू हो जाओ',
      try_pink_elephant: 'कोशिश करें..पहाड़ में गेंद पर नाचता गुलाबी हाथी।',
      artwork: 'कलाकृति',
      category: 'वर्ग',
      discover: 'खोज करना',
      search_users: 'उपयोगकर्ता, चित्र खोजें...',
      posts: 'पदों',
      supporters: 'समर्थकों',
      supporting: 'सहायक',
      bio: 'जैव',
      gallery: 'गैलरी',
      collaborations: 'सहयोग',
      settings: 'समायोजन',
      tokens_left: 'टोकन बचे हैं',
      account_settings: 'अकाउंट सेटिंग',
      privacy_policy: 'गोपनीयता नीति',
      terms_of_use: 'उपयोग की शर्तें',
      leaderboard: 'लीडरबोर्ड',
      contact_us: 'संपर्क करें',
      logout: 'लॉग आउट',
      edit_profile: 'प्रोफ़ाइल संपादित करें',
      change_password: 'पासवर्ड बदलें',
      languages: 'बोली',
      currency: 'मुद्रा',
      delete_account: 'खाता हटा दो',
      tip_msg:
        'जब आप अपना खाता हटाते हैं तो आपका सारा डेटा और माइंडफुलनेस के उपकरण हटा दिए जाएंगे',
      are_you_sure_you_want_to_delete_your_account:
        'क्या आप इस खाते को हटाने के लिए सुनिश्चित हैं?',
      name: 'नाम',
      user_name: 'उपयोगकर्ता नाम',
      username: 'उपयोगकर्ता नाम',
      save_changes: 'परिवर्तनों को सुरक्षित करें',
      current_password: 'वर्तमान पासवर्ड',
      new_password: 'नया पासवर्ड',
      confirm_new_password: 'नए पासवर्ड की पुष्टि करें',
      save_new_password: 'नया पासवर्ड सेव करें',
      choose_languages: 'भाषाएँ चुनें',
      select_option: 'विकल्प चुनें',
      save_language: 'भाषा सहेजें',
      choose_currency: 'मुद्रा चुनिये',
      select_currency: 'मुद्रा चुनें',
      subject: 'विषय',
      message: 'संदेश',
      send: 'भेजना',
      press_and_speak_your_prompt: 'दबाएँ और अपना संकेत बोलें!',
      edit_your_photo_further:
        'अपनी फ़ोटो को और संपादित करें! साथ ही सहयोग करें!',
      image_saves_to_your_gallery_when_you_post:
        'जब आप पोस्ट करते हैं तो छवि आपकी गैलरी में सहेज ली जाती है!',
      make_more_from_your_words_and_search_for_users_and_other_images:
        'अपने शब्दों से और अधिक बनाएं तथा उपयोगकर्ताओं और अन्य छवियों को खोजें!',
      upload_photos_from_your_gallery_and_edit_them:
        'अपनी गैलरी से फ़ोटो अपलोड करें और उन्हें संपादित करें',
      check_your_notifications_here: 'अपनी सूचनाएं यहां देखें',
      press_to_support_and_collaborate: 'समर्थन और सहयोग करने के लिए दबाएँ',
      subscriptions: 'सदस्यता',
      tokens: 'टोकन',
      view_details: 'विवरण देखें',
      monthly: 'महीने के',
      Weekly: 'साप्ताहिक',
      Yearly: 'सालाना',
      most_popular: 'सबसे लोकप्रिय',
      upgrade_plan: 'उन्नयन योजना',
      buy_now: 'अभी खरीदें',
      plan_details: 'योजना विवरण',
      image_generator: 'छवि जनरेटर',
      unlimited_editing_tools: 'असीमित संपादन उपकरण',
      full_collaboration_capabilities: 'पूर्ण सहयोग क्षमताएँ',
      title: 'शीर्षक',
      created_at: 'पर बनाया गया',
      comment: 'टिप्पणी',
      share_image: 'चित्र साझा करें',
      share_image_facebook: 'छवि फेसबुक, इंस्टाग्राम, Pinterest पर साझा करें',
      download_image: 'छवि डाउनलोड करें',
      related_artwork: 'संबंधित कलाकृति',
      no_creations_yet: 'अभी तक कोई रचना नहीं',
      turn_off_commenting: 'टिप्पणी करना बंद करें',
      invite_collaborations: 'सहयोग आमंत्रित करें',
      categories: 'श्रेणियाँ',
      hide_prompt: 'संकेत छिपाएँ',
      err_phone_msg: 'त्रुटि: कृपया अपने मित्र का ईमेल या फ़ोन नंबर दर्ज करें।',
      phone_number: 'फ़ोन नंबर',
      buy_more:
        'छवियाँ बनाना जारी रखने के लिए अधिक टोकन खरीदें/सदस्यता प्राप्त करें',
      ok: 'ठीक है',
      generated: 'जनरेट किया गया',
      view_variation: 'विविधताएँ देखें',
      variations: 'बदलाव',
      variation_msg:
        'आप इन विविधताओं में से चयन कर सकते हैं या पिछली पीढ़ी का चयन कर सकते हैं',
      new_category: 'नई श्रेणी',
      collaborate: 'सहयोग',
      place_search_user: 'उपयोगकर्ता खोजें',
      new_post: 'नई पोस्ट',
      write_caption: 'कैप्शन लिखें...',
      peoples: 'पीपुल्स',
      access_collaboration:
        'दूसरों का समर्थन करके और उन्हें अपना समर्थन देकर सहयोग तक पहुंचें!',
      artworks: 'कलाकृतियों',
      users: 'उपयोगकर्ताओं',
      notifications: 'सूचनाएं',
      success: 'सफलता',
      error_code: 'त्रुटि कोड',
      unsubscribe: 'सदस्यता रद्द',
      connecting_appstore:
        'ऐपस्टोर कनेक्ट हो रहा है, कृपया एक सेकंड प्रतीक्षा करें और पुनः प्रयास करें',
      purchased: 'खरीदी',
      save: 'बचाना',
      only: 'केवल',
      your_name: 'आपका नाम',
      liked_images: 'छवियाँ पसंद आईं',
      support: 'सहायता',
      weekly_plan_details: 'साप्ताहिक योजना विवरण',
      monthly_plan_details: 'मासिक योजना विवरण',
      yearly_plan_details: 'वार्षिक योजना विवरण',
      logo_tip:
        'युक्ति: नेविगेट करने के लिए स्क्रीन के नीचे AIKO लोगो बटन का उपयोग करें।',
      got_it: 'ठीक है। समझ गया',
      instructions: 'निर्देश',
      no_bullying: 'कोई बदमाशी/उत्पीड़न नहीं',
      no_nudity: 'कोई नग्नता/यौन सामग्री नहीं',
      young_visitors: '(हमारे पास युवा आगंतुक हैं)',
      no_political: 'कोई राजनीतिक अभियान नहीं',
      only_love: 'केवल प्रेम, सृजन और सहयोग',
      learn_more: 'और अधिक जानें',
      login: 'लॉग इन करें',
      welcome_back: 'वापसी पर स्वागत है',
      new_to_aiko: 'AIKO में नए हैं?',
      sign_up: 'साइन अप करें',
      continue: 'जारी रखना',
      forgot_passwword: 'पासवर्ड भूल गए',
      reset_password: 'पासवर्ड रीसेट लिंक आपके ईमेल पर भेजा जाएगा',
      sent_verification_code: 'हमने सत्यापन कोड भेज दिया है',
      enter_code: 'कृपया कोड दर्ज करें',
      enter_password: 'पास वर्ड दर्ज करें',
      enter_confirm_password: 'पासवर्ड की पुष्टि करें दर्ज करें',
      register: 'पंजीकरण करवाना',
      hello: 'नमस्कार, आइए एक-दूसरे को जानें',
      password: 'पासवर्ड',
      receive_invite: 'क्या आपको अपने मित्र से निमंत्रण प्राप्त हुआ?',
      optional: 'वैकल्पिक',
      delete: 'मिटाना',
      comments: 'टिप्पणियाँ',
      buy_plans: 'योजनाएं खरीदें',
      country: 'देश',
      more: 'अधिक',
      chat: 'बात करना',
      chatDetail: 'निजी चैट',
      commingSoon: 'जल्द आ रहा है !!!',
      stories: 'कहानियों',
      success_to_add_story: 'कहानी जोड़ने में सफलता',
      saved_to_gallery: 'गैलरी में सहेजा गया',
      add_story: 'कहानी जोड़ें',
      save_to_gallery: 'गैलरी में सहेजें',
      me: 'मुझे',
      viking_helmet: 'वाइकिंग हेलमेट',
      stallone: 'स्टेलोन',
      ping_pong: 'पिंग पोंग',
      humanoid: 'मानव सदृश',
      fire_effect: 'अग्नि प्रभाव',
      emotion_meter: 'भावना मीटर',
      emotions_exaggerator: 'भावना अतिशयोक्ति',
      look1: 'देखो1',
      look2: 'देखो2',
      aviators: 'उड़ाके',
      ball_face: 'गेंद का चेहरा',
      beard: 'दाढ़ी',
      beauty: 'सुंदरता',
      fairy_lights: 'मनोहर प्रकाश',
      background_segmentation: 'पृष्ठभूमि विभाजन',
      hair_segmentation: 'बाल विभाजन',
      flower_crown: 'पुष्प मुकुट',
      frankenstein: 'फ्रेंकस्टीन',
      lion: 'शेर',
      manly_face: 'मर्दाना चेहरा',
      plastic_ocean: 'प्लास्टिक महासागर',
      pumpkin: 'कद्दू',
      scuba: 'स्कूबा गोताखोर',
      tape_face: 'टेप चेहरा',
      tiny_sunglasses: 'छोटे धूप का चश्मा',
      topology: 'टोपोलॉजी',
      photo: 'तस्वीर',
      video: 'वीडियो',
      create_prompt_or_upload: 'प्रॉम्प्ट बनाएं या अपलोड करें',
      or: 'या',
      delete_story: 'कहानी हटाएँ',
      failed_to_get_stories: 'कहानियाँ प्राप्त करने में विफल',
      success_to_delete_story: 'कहानी हटाने में सफलता',
      create_background_image_with_prompt:
        'प्रॉम्प्ट के साथ पृष्ठभूमि छवि बनाएं',
      prompt: 'तत्पर',
      failed_to_get_all_supporters_by_user_id:
        'उपयोगकर्ता आईडी के आधार पर सभी समर्थकों को प्राप्त करने में विफल',
      failed_to_get_all_supportings_by_user_id:
        'उपयोगकर्ता आईडी द्वारा सभी समर्थन प्राप्त करने में विफल',
      failed_to_search_users: 'उपयोगकर्ताओं को खोजने में विफल',
      failed_to_search_posts: 'पोस्ट खोजने में विफल',
      share_as_post: 'पोस्ट के रूप में साझा करें',
      add_mentions: 'उल्लेख जोड़ें',
      sell_your_work: 'अपना काम बेचें',
      goods: 'माल',
      price_msg: 'कृपया इस छवि के लिए मूल्य जोड़ें',
      err_price_msg: 'त्रुटि: कृपया इनपुट मूल्य',
      buy: 'खरीद',
      register_card: 'रजिस्टर कार्ड',
      input_card_information: 'कृपया कार्ड की जानकारी सही ढंग से इनपुट करें.',
      register_card_info: 'रजिस्टर कार्ड जानकारी',
      ar_err: 'जल्द ही उपलब्ध होगा',
      // 'एआर वर्तमान में आईओएस 17.0 तक का समर्थन करता है। एआर को वर्तमान में आईओएस 17.0 के लिए अपडेट किया जा रहा है और जल्द ही उपलब्ध होगा।',
      create_story: 'कहानी बनाएं',
      use_ar: 'एआर का प्रयोग करें',
      use_ai_video: 'एआई वीडियो का प्रयोग करें',
      for_story:
        'स्टोरी बनाने के लिए आप AR या AI वीडियो जेनरेटर का उपयोग कर सकते हैं। एक का चयन करें।',
      create_ai_video: 'एआई वीडियो बनाएं',
      failed_to_generate_Video: 'वीडियो जनरेट करने में विफल',
      use_ai_video_audio: 'एआई डांस का प्रयोग करें',
      create_ai_video_audio: 'ऑडियो से वीडियो बनाएं',
      select_audio: 'ऑडियो फ़ाइलें चुनें',
      select_audio_desc: 'आप अधिकतम 10 ऑडियो फ़ाइलें चुन सकते हैं.',
      input_prompt: 'कृपया वीडियो बनाने के लिए शीघ्र इनपुट करें',
      generating_video: 'वीडियो जनरेट कर रहा है...',
      generated_video_msg:
        'आपकी कहानियों पर जेनरेट किए गए वीडियो जोड़े गए। कृपया स्टोरीज़ पेज पर वीडियो देखें।',
      create_more_files: 'आप 10 और वीडियो नहीं बना सकते',
      video_audio_popup:
        'युक्ति: जाओ अपना काम करो! जब आपके पसंदीदा संगीत वीडियो तैयार हो जाएंगे तो हम आपको सूचित करेंगे!',
      create_after_msg:
        'युक्ति: जाओ अपना काम करो! जब आपके पसंदीदा संगीत वीडियो तैयार हो जाएंगे तो हम आपको सूचित करेंगे!',
      ai_audio_video_prompt_tip:
        'एक पतंगे की पेंटिंग, एक हत्यारे ड्रैगनफ्लाई की एक पेंटिंग, गहरे समुद्र में दो मछलियाँ आपस में बात कर रही थीं, एक पतंगे की पेंटिंग,',
      audio_create_video: 'वीडियो बनाएं',
      audio_trimming: 'क्लिप ऑडियो',
      choose_clip_duration: 'क्लिप अवधि चुनें',
      generator_video: 'जेनरेटर वीडियो',
      second: 'सेकंड',
      spotify_home: 'Spotify घर',
      your_playlist: 'आपकी प्लेलिस्ट',
      add_to_playlist: 'प्लेलिस्ट में जोड़ें',
      expire_token: 'पहुंच टोकन समाप्त हो गया',
      search_music: 'संगीत खोजें',
      music_name: 'संगीत का नाम',
      status: 'स्थिति',
      completed: 'पुरा होना।',
      progressing: 'प्रगति',
      prompt1: 'तत्पर',
      music: 'संगीत',
      your_items: 'आपके आइटम',
      alert_uncomplete_video:
        'वीडियो जनरेट हो रहा है, कृपया जनरेट होने के बाद जांचें।',
      success_to_update_operation: 'कहानियों में जोड़ा गया',
      success_to_delete_operation: 'वीडियो हटा दिया गया',
      add_to_story: 'कहानियों में जोड़ें',
      are_you_sure_you_want_to_delete_this_audio:
        'क्या आप वाकई इस ऑडियो को हटाना चाहते हैं?',
      delete_video: 'वीडियो हटाएँ',
      limit_downloading_cnt:
        'आप एक ही समय में 3 से अधिक फ़ाइलें डाउनलोड नहीं कर सकते.',
      musics: 'संगीत',
      are_you_sure_want_to_delete : 'क्या आपको यकीन है! हटाना चाहते हैं',
      database :  'डाटाबेस',
      no_keep_it :  'नहीं, इसे रखें',
      yes_delete_it : 'हाँ, इसे हटाएँ',
      is_invite : 'क्या आपको अपने पद पर सहयोगी बनने के लिए आमंत्रित कर रहा है?',
      reject: 'अस्वीकार करना',
      accept: 'स्वीकार करना',
      my_profile : 'मेरी प्रोफ़ाइल',
      stripe_payment : 'पट्टी भुगतान',
      please_input_all_informations : 'कृपया सभी जानकारी दर्ज करें।',
      there_is_no_token : 'कोई बाएं टोकन नहीं है। कृपया टोकन खरीदें।',
      edit: 'संपादन करना',
      add: 'आगे कहना',
      profile_picture : 'प्रोफ़ाइल चित्र',
      status_complete: 'स्थिति: पूर्ण',
      status_progressing : 'स्थिति: प्रगति',
      story: 'कहानी',
      download: 'डाउनलोड',
      Home: 'को',
      Search: 'ढूँढ',
      Profile: 'ख़ाका',
      Setting: 'सेटिंग',
      ContactUs: 'हमसे संपर्क करें',
      log_out: 'लॉग आउट करें',
      dashboard: 'डैशबोर्ड',
      update_profile: 'प्रोफ़ाइल अद्यतन करें',
      HealthCheck: 'स्वास्थ्य जांच',
      birthday: 'जन्मदिन',
      Chat: 'बात करना',
    },
  },
};
