export default {
  translation: {
    all: {
      invite_msg: 'أضف أصدقائك واحصل على 5 أجيال مجانًا.',
      email: 'بريد إلكتروني',
      feed: 'يٌطعم',
      welcome: 'مرحباً',
      upload: 'رفع',
      nav_popup_msg: 'انقر لتبديل تشغيل / إيقاف تشغيل التنقل',
      buy_plan: 'خطط الشراء',
      profile: 'حساب تعريفي',
      search: 'يبحث',
      create: 'يخلق',
      feeds: 'يغذي',
      image_edit: 'تحرير الصورة',
      generate_edited_image: 'توليد الصورة المحررة',
      edit_image: 'تعديل الصورة',
      cancel: 'يلغي',
      failed_get_user: 'فشل الحصول على المستخدم',
      failed_get_image: 'فشل الحصول على الصورة',
      you_already_liked_this_image: 'لقد أحببت هذه الصورة بالفعل.',
      failed_to_set_like: 'فشل تعيين مثل',
      invalid_password: 'رمز مرور خاطئ',
      email_already_registered:
        'البريد الإلكتروني مسجل مسبقا. الرجاء محاولة بريد إلكتروني آخر.',
      failed_to_reset_password: 'فشل إعادة تعيين كلمة المرور',
      failed_to_get_user_profile: 'فشل في الحصول على ملف تعريف المستخدم',
      failed_to_subscribe_yearly: 'فشل في الاشتراك سنويا',
      failed_to_subscribe_monthly: 'فشل الاشتراك شهريا',
      failed_to_subscribe_weekly: 'فشل في الاشتراك أسبوعيا',
      failed_to_unsubscribe: 'فشل إلغاء الاشتراك',
      failed_to_purchase_tokens: 'فشل في شراء الرموز',
      failed_to_remove_one_token: 'فشل إزالة رمز مميز واحد.',
      failed_to_get_all_categories: 'فشل في الحصول على جميع الفئات',
      failed_to_get_all_posts_on_search_page:
        'فشل الحصول على جميع المشاركات في صفحة البحث',
      failed_to_get_posts: 'فشل الحصول على المشاركات',
      failed_to_get_all_posts_on_profile_page:
        'فشل الحصول على جميع المشاركات على صفحة الملف الشخصي',
      please_input_email_address: 'الرجاء إدخال عنوان البريد الإلكتروني.',
      error_occurred_during_sending_message_via_nodemailer:
        'حدث خطأ أثناء إرسال الرسالة عبر nodemailer',
      please_input_code: 'الرجاء إدخال الكود.',
      error_occurred_during_verify_code: 'حدث خطأ أثناء رمز التحقق',
      success_to_reset_password: 'نجح إعادة تعيين كلمة المرور',
      error_occurred_during_reset_password:
        'حدث خطأ أثناء إعادة تعيين كلمة المرور',
      please_input_name: 'الرجاء إدخال الاسم',
      please_input_user_name: 'الرجاء إدخال اسم المستخدم',
      please_input_email: 'الرجاء إدخال البريد الإلكتروني',
      invalidate_email: 'البريد الإلكتروني غير صالح ، يرجى المحاولة مرة أخرى.',
      please_input_password: 'يرجى إدخال كلمة السر',
      please_input_country: 'الرجاء إدخال البلد',
      please_input_last_name: 'الرجاء إدخال الاسم الأخير',
      failed_to_get_user_profile_by_id:
        'فشل في الحصول على ملف تعريف المستخدم عن طريق المعرف',
      failed_to_get_one_post_by_id: 'فشل الحصول على وظيفة واحدة عن طريق المعرف',
      failed_to_get_all_posts_on_artwork:
        'فشل الحصول على جميع المشاركات في العمل الفني',
      there_is_no_permission_to_edit_this_image:
        'لا يوجد إذن لتعديل هذه الصورة.',
      failed_to_add_new_comment: 'فشل في إضافة تعليق جديد.',
      failed_to_delete_post: 'فشل حذف آخر.',
      failed_to_get_post_on_comment_screen:
        'فشل الحصول على المشاركة على شاشة التعليق',
      saved_image: 'الصورة المحفوظة.',
      failed_to_get_openai_images: 'فشل الحصول على صور openai',
      please_input_prompt: 'الرجاء إدخال موجه.',
      please_do_not_use_this_word: 'من فضلك لا تستخدم هذه الكلمة',
      confirm_your_selection: 'قم بتأكيد اختيارك.',
      please_select_image: 'الرجاء تحديد الصورة.',
      saved_selected_images: 'الصور المختارة المحفوظة.',
      for_all_editing_capabilities:
        'لجميع إمكانيات التحرير ، يرجى الحصول على الرموز / الاشتراك.',
      please_select_category: 'الرجاء تحديد الفئة.',
      failed_to_send_invite: 'فشل إرسال الدعوة',
      failed_to_post: 'فشل النشر',
      failed_to_get_users: 'فشل الحصول على المستخدمين',
      failed_to_save_image: 'فشل حفظ الصورة',
      already_added_user: 'تمت إضافة المستخدم بالفعل',
      failed_to_get_user_by_id_on_support:
        'فشل الحصول على المستخدم عن طريق المعرف على الدعم',
      success_to_add_supporter: 'النجاح في إضافة مؤيد.',
      failed_to_set_supporter: 'فشل تعيين الداعم',
      failed_to_delete_account: 'فشل حذف الحساب',
      please_input_customer_name_for_payment: 'الرجاء إدخال اسم العميل للدفع.',
      your_order_is_confirmed: 'تم تأكيد طلبك!',
      your_order_is_unsubscribed: 'طلبك غير مشترك!',
      please_select_currency: 'الرجاء تحديد العملة.',
      please_select_language: 'الرجاء تحديد اللغة.',
      please_input_fields: 'الرجاء إدخال الحقول.',
      please_mismatch_password: 'الرجاء عدم تطابق كلمة المرور.',
      please_input_your_name: 'الرجاء إدخال اسمك.',
      please_input_subject: 'الرجاء إدخال الموضوع.',
      please_input_message: 'الرجاء إدخال الرسالة.',
      success_to_sent_message: 'نجاح الرسالة المرسلة.',
      failed_to_sent_message: 'فشل في إرسال الرسالة.',
      failed_to_get_user: 'فشل الحصول على المستخدم',
      creations_for_you: 'من أجلك إبداعات. بواسطتك.',
      global_generative_ai_Platform: 'منصة الذكاء الاصطناعي التوليدية العالمية',
      get_started: 'البدء',
      try_pink_elephant: 'جرب .. الفيل الوردي يرقص على كرة في الجبل.',
      artwork: 'عمل فني',
      category: 'فئة',
      discover: 'يكتشف',
      search_users: 'البحث عن المستخدمين والصور ...',
      posts: 'دعامات',
      supporters: 'أنصار',
      supporting: 'دعم',
      bio: 'السيرة الذاتية',
      gallery: 'صالة عرض',
      collaborations: 'التعاون',
      settings: 'إعدادات',
      tokens_left: 'الرموز المتبقية',
      account_settings: 'إعدادت الحساب',
      privacy_policy: 'سياسة الخصوصية',
      terms_of_use: 'شروط الاستخدام',
      leaderboard: 'ليدربورد',
      contact_us: 'اتصل بنا',
      logout: 'تسجيل خروج',
      edit_profile: 'تعديل الملف الشخصي',
      change_password: 'تغيير كلمة المرور',
      languages: 'اللغات',
      currency: 'عملة',
      delete_account: 'حذف الحساب',
      tip_msg: 'عند حذف حسابك ، سيتم حذف جميع بياناتك وأدوات اليقظة',
      are_you_sure_you_want_to_delete_your_account:
        'هل انت متأكد انك تريد حذف حسابك؟',
      name: 'اسم',
      user_name: 'اسم المستخدم',
      username: 'اسم المستخدم',
      save_changes: 'احفظ التغييرات',
      current_password: 'كلمة السر الحالية',
      new_password: 'كلمة المرور الجديدة',
      confirm_new_password: 'تأكيد كلمة المرور الجديدة',
      save_new_password: 'احفظ كلمة المرور الجديدة',
      choose_languages: 'اختر اللغات',
      select_option: 'حدد الخيار',
      save_language: 'حفظ اللغة',
      choose_currency: 'اختر العملة',
      select_currency: 'اختر العملة',
      subject: 'موضوع',
      message: 'رسالة',
      send: 'يرسل',
      press_and_speak_your_prompt: 'اضغط وتحدث موجهك!',
      edit_your_photo_further: 'تحرير صورتك كذلك! تعاون أيضًا!',
      image_saves_to_your_gallery_when_you_post:
        'يحفظ الصورة في معرض الصور الخاص بك عندما تنشر!',
      make_more_from_your_words_and_search_for_users_and_other_images:
        'استفد أكثر من كلماتك وابحث عن مستخدمين وصور أخرى!',
      upload_photos_from_your_gallery_and_edit_them:
        'قم بتحميل الصور من معرض الصور الخاص بك وقم بتحريرها',
      check_your_notifications_here: 'تحقق من الإخطارات الخاصة بك هنا',
      press_to_support_and_collaborate: 'اضغط للدعم والتعاون',
      subscriptions: 'الاشتراكات',
      tokens: 'الرموز',
      view_details: 'عرض التفاصيل',
      monthly: 'شهريا',
      Weekly: 'أسبوعي',
      Yearly: 'سنوي',
      most_popular: 'الأكثر شهرة',
      upgrade_plan: 'خطة الترقية',
      buy_now: 'اشتري الآن',
      plan_details: 'تفاصيل الخطة',
      image_generator: 'مولد الصور',
      unlimited_editing_tools: 'أدوات تحرير غير محدودة',
      full_collaboration_capabilities: 'قدرات تعاون كاملة',
      title: 'عنوان',
      created_at: 'أنشئت في',
      comment: 'تعليق',
      share_image: 'مشاركة الصورة',
      share_image_facebook:
        'مشاركة الصورة على facebook و Instagram و Pinterest',
      download_image: 'تحميل الصورة',
      related_artwork: 'الأعمال الفنية ذات الصلة',
      no_creations_yet: 'لا إبداعات حتى الآن',
      turn_off_commenting: 'أوقف التعليق',
      invite_collaborations: 'دعوة التعاون',
      categories: 'فئات',
      hide_prompt: 'إخفاء المطالبة',
      err_phone_msg:
        'خطأ: الرجاء إدخال البريد الإلكتروني أو رقم الهاتف الخاص بصديقك.',
      phone_number: 'رقم التليفون',
      buy_more: 'شراء المزيد من الرموز / احصل على اشتراك لمتابعة توليد الصور',
      ok: 'نعم',
      generated: 'ولدت',
      view_variation: 'مشاهدة ملف Variations',
      variations: 'Variations',
      variation_msg:
        'يمكنك الاختيار من بين هذه الاختلافات أو يمكنك تحديد الجيل السابق',
      new_category: 'فئة جديدة',
      collaborate: 'يتعاون',
      place_search_user: 'بحث في المستخدمين',
      new_post: 'منشور جديد',
      write_caption: 'كتابة تعليق ...',
      peoples: 'الشعوب',
      access_collaboration: 'الوصول إلى التعاون من خلال دعم الآخرين ودعمهم لك!',
      artworks: 'اعمال فنية',
      users: 'المستخدمون',
      notifications: 'إشعارات',
      success: 'نجاح',
      error_code: 'خطا بالكود',
      unsubscribe: 'إلغاء الاشتراك',
      connecting_appstore:
        'جارٍ الاتصال بـ appstore ، يُرجى الانتظار قليلاً والمحاولة مرة أخرى',
      purchased: 'تم شراؤها',
      save: 'يحفظ',
      only: 'فقط',
      your_name: 'اسمك',
      liked_images: 'الصور المعجب بها',
      support: 'يدعم',
      weekly_plan_details: 'تفاصيل الخطة الأسبوعية',
      monthly_plan_details: 'تفاصيل الخطة الشهرية',
      yearly_plan_details: 'تفاصيل الخطة السنوية',
      logo_tip: 'نصيحة: استخدم زر شعار AIKO أسفل الشاشة للتنقل.',
      got_it: 'نعم. فهمتها',
      instructions: 'تعليمات',
      no_bullying: 'لا تنمر / مضايقة',
      no_nudity: 'لا يوجد عُري / محتوى جنسي',
      young_visitors: '(لدينا زوار شباب)',
      no_political: 'لا حملات سياسية',
      only_love: 'فقط الحب والخلق والتعاون',
      learn_more: 'يتعلم أكثر',
      login: 'تسجيل الدخول',
      welcome_back: 'مرحبًا بعودتك',
      new_to_aiko: 'جديد في AIKO؟',
      sign_up: 'اشتراك',
      continue: 'يكمل',
      forgot_passwword: 'هل نسيت كلمة السر',
      reset_password:
        'سيتم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني',
      sent_verification_code: 'لقد أرسلنا رمز التحقق إلى',
      enter_code: 'الرجاء إدخال الرمز',
      enter_password: 'أدخل كلمة المرور',
      enter_confirm_password: 'أدخل تأكيد كلمة المرور',
      register: 'يسجل',
      hello: 'مرحبًا بكم ، دعنا نتعرف على بعضنا البعض',
      password: 'كلمة المرور',
      receive_invite: 'هل تلقيت دعوة من صديقك؟',
      optional: 'خياري',
      delete: 'يمسح',
      comments: 'تعليقات',
      buy_plans: 'خطط الشراء',
      country: 'دولة',
      more: 'أكثر',
      chat: 'محادثة',
      chatDetail: 'دردشة خاصة',
      commingSoon: 'قريباً !!!',
      stories: 'قصص',
      success_to_add_story: 'نجاح لإضافة القصة',
      saved_to_gallery: 'تم الحفظ في المعرض',
      add_story: 'أضف قصة',
      save_to_gallery: 'حفظ في المعرض',
      me: 'أنا',
      viking_helmet: 'خوذة الفايكنج',
      stallone: 'ستالون',
      ping_pong: 'بينج بونج',
      humanoid: 'شبيه بالإنسان',
      fire_effect: 'تأثير النار',
      emotion_meter: 'مقياس العاطفة',
      emotions_exaggerator: 'المبالغة في العاطفة',
      look1: 'انظر1',
      look2: 'نظرة2',
      aviators: 'الطيارين',
      ball_face: 'وجه الكرة',
      beard: 'لحية',
      beauty: 'جمال',
      fairy_lights: 'أضواء خرافية',
      background_segmentation: 'تجزئة الخلفية',
      hair_segmentation: 'تجزئة الشعر',
      flower_crown: 'تاج الزهرة',
      frankenstein: 'فرانكشتاين',
      lion: 'أسد',
      manly_face: 'وجه رجولي',
      plastic_ocean: 'المحيط البلاستيكي',
      pumpkin: 'يقطين',
      scuba: 'غواص',
      tape_face: 'وجه الشريط',
      tiny_sunglasses: 'النظارات الشمسية الصغيرة',
      topology: 'البنية',
      photo: 'صورة',
      video: 'فيديو',
      create_prompt_or_upload: 'إنشاء مطالبة أو تحميل',
      or: 'أو',
      delete_story: 'حذف القصة',
      failed_to_get_stories: 'تعذّر الحصول على القصص',
      success_to_delete_story: 'نجاح حذف القصة',
      create_background_image_with_prompt: 'إنشاء صورة خلفية مع موجه',
      prompt: 'اِسْتَدْعَى',
      failed_to_get_all_supporters_by_user_id:
        'فشل في الحصول على كافة المؤيدين بواسطة معرف المستخدم',
      failed_to_get_all_supportings_by_user_id:
        'فشل في الحصول على كافة الدعم بواسطة معرف المستخدم',
      failed_to_search_users: 'فشل البحث عن المستخدمين',
      failed_to_search_posts: 'فشل في البحث عن المشاركات',
      share_as_post: 'شارك كمنشور',
      add_mentions: 'إضافة الإشارات',
      sell_your_work: 'بيع عملك',
      goods: 'متاع',
      price_msg: 'يرجى إضافة سعر لهذه الصورة',
      err_price_msg: 'خطأ: يرجى إدخال السعر',
      buy: 'ابتاع',
      register_card: 'تسجيل بطاقة',
      input_card_information: 'يرجى إدخال معلومات البطاقة بشكل صحيح.',
      register_card_info: 'تسجيل معلومات البطاقة',
      ar_err: 'سيتوفر قريبا',
      // 'يدعم AR حاليا حتى iOS 17.0. يتم حاليا تحديث AR لنظام iOS 17.0 وسيكون متاحا قريبا',
      create_story: 'إنشاء قصة',
      use_ar: 'استخدم الواقع المعزز',
      use_ai_video: 'استخدم فيديو الذكاء الاصطناعي',
      for_story:
        'لإنشاء قصة، يمكنك استخدام AR أو AI Video Generator. إختر واحد。 من فضلك.',
      create_ai_video: 'إنشاء فيديو بالذكاء الاصطناعي',
      failed_to_generate_Video: 'فشل في إنشاء الفيديو',
      use_ai_video_audio: 'استخدم رقصة الذكاء الاصطناعي',
      create_ai_video_audio: 'إنشاء فيديو من الصوت',
      select_audio: 'حدد ملفات الصوت',
      select_audio_desc: 'يمكنك تحديد ما يصل إلى 10 ملفات صوتية.',
      input_prompt: 'يرجى إدخال المطالبة لإنشاء الفيديو',
      generating_video: 'جارٍ إنشاء مقاطع فيديو...',
      generated_video_msg:
        'تمت إضافة مقاطع الفيديو التي تم إنشاؤها إلى قصصك. يرجى التحقق من أشرطة الفيديو على صفحة القصص.',
      create_more_files: 'لا يمكنك إنشاء 10 مقاطع فيديو أخرى',
      video_audio_popup:
        'نصيحة: اذهب افعل ما تريد! سنخبرك عند إنشاء مقاطع الفيديو الموسيقية المفضلة لديك!',
      create_after_msg:
        'نصيحة: اذهب افعل ما تريد! سنخبرك عند إنشاء مقاطع الفيديو الموسيقية المفضلة لديك!',
      ai_audio_video_prompt_tip:
        'لوحة فنية للفراشة، لوحة اليعسوب القاتل, سمكتان تتحدثان مع بعضهما البعض في أعماق البحر، لوحة فنية للفراشة،',
      audio_create_video: 'إنشاء فيديو',
      audio_trimming: 'مقطع صوتي',
      choose_clip_duration: 'اختر مدة المقطع',
      generator_video: 'فيديو المولد',
      second: 'ثواني',
      spotify_home: 'سبوتيفي الرئيسية',
      your_playlist: 'قوائم التشغيل الخاصة بك',
      add_to_playlist: 'أضف إلى قائمة التشغيل',
      expire_token: 'انتهت صلاحية رمز الوصول',
      search_music: 'بحث الموسيقى',
      music_name: 'اسم الموسيقى',
      status: 'حالة',
      completed: 'مكتمل',
      progressing: 'التقدم',
      prompt1: 'اِسْتَدْعَى',
      music: 'موسيقى',
      your_items: 'العناصر الخاصة بك',
      alert_uncomplete_video: 'يتم إنشاء الفيديو، يرجى التحقق بعد إنشائه.',
      success_to_update_operation: 'تمت إضافتها إلى القصص',
      success_to_delete_operation: 'الفيديو المحذوف',
      add_to_story: 'أضف إلى القصص',
      are_you_sure_you_want_to_delete_this_audio:
        'هل أنت متأكد أنك تريد حذف هذا الصوت؟',
      delete_video: 'حذف الفيديو',
      limit_downloading_cnt: 'لا يمكنك تحميل أكثر من 3 ملفات في نفس الوقت.',
      musics: 'موسيقي',
      are_you_sure_want_to_delete : 'هل أنت متأكد! تريد حذف',
      database :  'قاعدة بيانات',
      no_keep_it :  'لا، احتفظ بها',
      yes_delete_it : 'نعم، احذفه',
      is_invite : 'هل يدعوك لتكون متعاونا في منشورهم؟',
      reject: 'رفض',
      accept: 'تقبل',
      my_profile : 'ملفي الشخصي',
      stripe_payment : 'الدفع الشريطي',
      please_input_all_informations : 'يرجى إدخال جميع المعلومات.',
      there_is_no_token : 'لا يوجد رمز اليسار. يرجى شراء الرموز.',
      edit: 'حرر',
      add: 'جمع',
      profile_picture : 'صورة الملف الشخصي',
      status_complete:  'الحالة : مكتمل',
      status_progressing :  'الحالة : جاري',
      story: 'تَمْثِيلِيَّة',
      download: 'تحميل',
      Home: 'وطن',
      Search: 'بحث',
      Profile: 'ملف تعريف',
      Setting: 'اعداد',
      ContactUs: 'اتصل بنا',
      log_out: 'تسجيل الخروج',
      dashboard: 'لوحه القياده',
      update_profile: 'تحديث الملف الشخصي',
      HealthCheck: 'فحص طبي',
      birthday: 'عيد ميلاد',
      Chat: 'محادثة',
    },
  },
};
