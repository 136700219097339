import React, { createContext, useReducer } from 'react';
export const PostContext = createContext();

const initialState = {
  feeds: [],
  posts: [],
  profilePosts: [],
  profilePostCnt: 0,
  categories: [],
  isLoading: false,
  hasErr: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_FEED': {
      return {
        ...state,
        feeds: action.payload.data,
        hasErr: false,
      };
    }
    case 'UPDATE_POSTS': {
      const { feeds, posts, prs } = action.payload;
      return {
        ...state,
        feeds: feeds,
        posts: posts,
        profilePosts: prs,
        hasErr: false,
      };
    }
    case 'SERACH_FEEDS': {
      return {
        ...state,
        feeds: action.payload.data,
        hasErr: false,
      };
    }
    case 'GET_ALL_POSTS': {
      return {
        ...state,
        posts: action.payload.data,
        hasErr: false,
      };
    }
    case 'GET_CATEGORIES': {
      return {
        ...state,
        categories: action.payload.data,
        hasErr: false,
      };
    }
    case 'GET_PROFILE_POSTS': {
      return {
        ...state,
        profilePosts: action.payload.data,
        profilePostCnt: action.payload.cnt,
        hasErr: false,
      };
    }
    case 'ERROR': {
      return { ...state, hasErr: true };
    }
    default: {
      return state;
    }
  }
}

export const PostProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <PostContext.Provider value={value}>{children}</PostContext.Provider>
  );
};
