export default {
  translation: {
    all: {
      invite_msg: 'Добавь друзей и получи 5 поколений бесплатно.',
      email: 'Электронная почта',
      feed: 'Кормить',
      welcome: 'Добро пожаловать',
      upload: 'Загрузить',
      nav_popup_msg: 'Нажмите, чтобы включить/выключить навигацию',
      buy_plan: 'Acheter des forfaits',
      profile: 'Профиль',
      search: 'Поиск',
      create: 'Создавать',
      feeds: 'Фиды',
      image_edit: 'Редактировать изображение',
      generate_edited_image: 'Создать отредактированное изображение',
      edit_image: 'Редактировать изображение',
      cancel: 'Отмена',
      failed_get_user: 'Не удалось получить пользователя',
      failed_get_image: 'Не удалось получить изображение',
      you_already_liked_this_image: 'Вам уже понравилось это изображение.',
      failed_to_set_like: 'Не удалось поставить лайк',
      invalid_password: 'Неверный пароль',
      email_already_registered:
        'Электронная почта уже зарегистрирована. Пожалуйста, попробуйте другой адрес электронной почты.',
      failed_to_reset_password: 'Не удалось сбросить пароль',
      failed_to_get_user_profile: 'Не удалось получить профиль пользователя',
      failed_to_subscribe_yearly: 'Не удалось подписаться на год',
      failed_to_subscribe_monthly: 'Не удалось подписаться ежемесячно',
      failed_to_subscribe_weekly: 'Не удалось подписаться еженедельно',
      failed_to_unsubscribe: 'Не удалось отписаться',
      failed_to_purchase_tokens: 'Не удалось купить токены',
      failed_to_remove_one_token: 'Не удалось удалить один токен.',
      failed_to_get_all_categories: 'Не удалось получить все категории',
      failed_to_get_all_posts_on_search_page:
        'Не удалось получить все сообщения на странице поиска',
      failed_to_get_posts: 'Не удалось получить сообщения',
      failed_to_get_all_posts_on_profile_page:
        'Не удалось получить все сообщения на странице профиля',
      please_input_email_address: 'Please input email address.',
      error_occurred_during_sending_message_via_nodemailer:
        'Error occurred during sending message via nodemailer',
      please_input_code: 'Пожалуйста, введите код.',
      error_occurred_during_verify_code: 'Произошла ошибка при проверке кода',
      success_to_reset_password: 'Успешный сброс пароля',
      error_occurred_during_reset_password:
        'Произошла ошибка при сбросе пароля',
      please_input_name: 'Пожалуйста, введите имя',
      please_input_user_name: 'Пожалуйста, введите имя пользователя',
      please_input_email: 'Пожалуйста, введите адрес электронной почты',
      invalidate_email:
        'Недействительный адрес электронной почты. Повторите попытку.',
      please_input_password: 'Пожалуйста, введите пароль',
      please_input_country: 'Пожалуйста, введите страну',
      please_input_last_name: 'Пожалуйста, введите фамилию',
      failed_to_get_user_profile_by_id:
        'Не удалось получить профиль пользователя по идентификатору',
      failed_to_get_one_post_by_id: 'Не удалось получить один пост по id',
      failed_to_get_all_posts_on_artwork:
        'Не удалось получить все сообщения о произведениях искусства.',
      there_is_no_permission_to_edit_this_image:
        'Нет разрешения на редактирование этого изображения.',
      failed_to_add_new_comment: 'Не удалось добавить новый комментарий.',
      failed_to_delete_post: 'Не удалось удалить сообщение.',
      failed_to_get_post_on_comment_screen:
        'Не удалось получить публикацию на экране комментариев',
      saved_image: 'Сохраненное изображение.',
      failed_to_get_openai_images: 'Не удалось получить изображения openai',
      please_input_prompt: 'Пожалуйста, введите подсказку',
      please_do_not_use_this_word: 'Пожалуйста, не используйте это слово',
      confirm_your_selection: 'Подтвердите свой выбор.',
      please_select_image: 'Пожалуйста, выберите изображение.',
      saved_selected_images: 'Сохраненные выбранные изображения.',
      for_all_editing_capabilities:
        'Для всех возможностей редактирования, пожалуйста, получите токены/подписку.',
      please_select_category: 'Пожалуйста, выберите категорию.',
      failed_to_send_invite: 'Не удалось отправить приглашение',
      failed_to_post: 'Не удалось опубликовать',
      failed_to_get_users: 'Не удалось получить пользователей',
      failed_to_save_image: 'Не удалось сохранить изображение',
      already_added_user: 'Уже добавленный пользователь',
      failed_to_get_user_by_id_on_support:
        'Не удалось получить пользователя по id на техподдержке',
      success_to_add_supporter: 'Добавление сторонника успешно выполнено.',
      failed_to_set_supporter: 'Не удалось установить сторонника',
      failed_to_delete_account: 'Не удалось удалить аккаунт',
      please_input_customer_name_for_payment:
        'Пожалуйста, введите имя клиента для оплаты.',
      your_order_is_confirmed: 'Ваш заказ подтвержден!',
      your_order_is_unsubscribed: 'Ваш заказ отписан!',
      please_select_currency: 'Пожалуйста, выберите Валюта.',
      please_select_language: 'Выберите язык.',
      please_input_fields: 'Пожалуйста, введите поля.',
      please_mismatch_password: 'Неверный пароль.',
      please_input_your_name: 'Пожалуйста, введите ваше имя.',
      please_input_subject: 'Пожалуйста, введите тему.',
      please_input_message: 'Пожалуйста, введите сообщение.',
      success_to_sent_message: 'Сообщение успешно отправлено.',
      failed_to_sent_message: 'Не удалось отправить сообщение.',
      failed_to_get_user: 'Не удалось получить пользователя',
      creations_for_you: 'Творения, Для вас. Тобой.',
      global_generative_ai_Platform: 'Глобальная платформа генеративного ИИ',
      get_started: 'Начать',
      try_pink_elephant: 'Попробуйте.. Розовый слон танцует на мяче в горах.',
      artwork: 'Работа',
      category: 'Категория',
      discover: 'Обнаружить',
      search_users: 'Поиск пользователей, картинки...',
      posts: 'Сообщения',
      supporters: 'Сторонники',
      supporting: 'Поддержка',
      bio: 'Био',
      gallery: 'Галерея',
      collaborations: 'Сотрудничество',
      settings: 'Настройки',
      tokens_left: 'ЖЕЛЕЗЫ ОСТАЛИСЬ',
      account_settings: 'Настройки учетной записи',
      privacy_policy: 'Политика конфиденциальности',
      terms_of_use: 'Условия эксплуатации',
      leaderboard: 'Таблица лидеров',
      contact_us: 'Связаться с нами',
      logout: 'Выйти',
      edit_profile: 'Редактировать профиль',
      change_password: 'Изменить пароль',
      languages: 'Языки',
      currency: 'Валюта',
      delete_account: 'Удалить аккаунт',
      tip_msg:
        'Когда вы удаляете свою учетную запись, все ваши данные и инструменты осознанности будут удалены.',
      are_you_sure_you_want_to_delete_your_account:
        'Вы уверены, что хотите удалить свой аккаунт?',
      name: 'Имя',
      user_name: 'Имя пользователя',
      username: 'Имя пользователя',
      save_changes: 'Sauvegarder les modifications',
      current_password: 'Текущий пароль',
      new_password: 'Новый пароль',
      confirm_new_password: 'Подтвердите новый пароль',
      save_new_password: 'Сохранить новый пароль',
      choose_languages: 'Выберите языки',
      select_option: 'Выберите опцию',
      save_language: 'Сохранить язык',
      choose_currency: 'Выберите Валюту',
      select_currency: 'Выберите валюту',
      subject: 'Предмет',
      message: 'Сообщение',
      send: 'Отправлять',
      press_and_speak_your_prompt: 'Нажмите и произнесите свою подсказку!',
      edit_your_photo_further:
        'Редактируйте свою фотографию дальше! Также Сотрудничайте!',
      image_saves_to_your_gallery_when_you_post:
        'Изображение сохраняется в вашей галерее, когда вы публикуете!',
      make_more_from_your_words_and_search_for_users_and_other_images:
        'Сделайте больше из своих слов и ищите пользователей и другие изображения!',
      upload_photos_from_your_gallery_and_edit_them:
        'Загружайте фотографии из своей галереи и редактируйте их',
      check_your_notifications_here: 'Проверьте свои уведомления здесь',
      press_to_support_and_collaborate:
        'Нажмите, чтобы поддержать и сотрудничать',
      subscriptions: 'Abonnements',
      tokens: 'Токены',
      view_details: 'Посмотреть детали',
      monthly: 'Ежемесячно',
      Weekly: 'Еженедельно',
      Yearly: 'Ежегодно',
      most_popular: 'Самый популярный',
      upgrade_plan: 'План обновления',
      buy_now: 'Купить сейчас',
      plan_details: 'Детали плана',
      image_generator: 'Генератор изображений',
      unlimited_editing_tools: 'Неограниченные инструменты редактирования',
      full_collaboration_capabilities: 'Полные возможности совместной работы',
      title: 'Заголовок',
      created_at: 'Создан в',
      comment: 'Комментарий',
      share_image: 'Поделиться изображением',
      share_image_facebook:
        'Поделиться изображением в Facebook, Instagram, Pinterest',
      download_image: 'Скачать изображение',
      related_artwork: 'Похожие произведения искусства',
      no_creations_yet: 'Пока нет творений',
      turn_off_commenting: 'Отключить комментарии',
      invite_collaborations: 'Пригласить к сотрудничеству',
      categories: 'Категории',
      hide_prompt: 'Скрыть подсказку',
      err_phone_msg:
        'ошибка: введите адрес электронной почты или номер телефона вашего друга.',
      phone_number: 'Номер телефона',
      buy_more:
        'Купите больше токенов/получите подписку, чтобы продолжить генерацию изображений',
      ok: 'ХОРОШО',
      generated: 'Сгенерировано',
      view_variation: 'Посмотреть варианты',
      variations: 'Вариации',
      variation_msg:
        'Вы можете выбрать из этих вариантов или можете выбрать предыдущее поколение',
      new_category: 'Новая категория',
      collaborate: 'Сотрудничать',
      place_search_user: 'Поиск пользователей',
      new_post: 'Новый пост',
      write_caption: 'Написать подпись...',
      peoples: 'Народы',
      access_collaboration:
        'Получите доступ к сотрудничеству, поддерживая других и заставляя их поддерживать вас!',
      artworks: 'Произведения',
      users: 'Пользователи',
      notifications: 'Уведомления',
      success: 'Успех',
      error_code: 'Код ошибки',
      unsubscribe: 'Отписаться',
      connecting_appstore:
        'Подключение магазина приложений. Подождите секунду и повторите попытку.',
      purchased: 'Куплено',
      save: 'Сохранять',
      only: 'Только',
      your_name: 'Ваше имя',
      liked_images: 'Понравившиеся изображения',
      support: 'Поддерживать',
      weekly_plan_details: 'Подробности еженедельного плана',
      monthly_plan_details: 'Детали ежемесячного плана',
      yearly_plan_details: 'Детали годового плана',
      logo_tip:
        'Совет: используйте кнопку с логотипом AIKO в нижней части экрана для навигации.',
      got_it: 'хорошо. понятно',
      instructions: 'ИНСТРУКЦИИ',
      no_bullying: 'НЕТ хулиганству/притеснению',
      no_nudity: 'НЕТ наготы/сексуального содержания',
      young_visitors: '(У нас есть молодые посетители)',
      no_political: 'НЕТ политических кампаний',
      only_love: 'Только Любовь, Творчество и Сотрудничество',
      learn_more: 'УЗНАТЬ БОЛЬШЕ',
      login: 'Авторизоваться',
      welcome_back: 'Добро пожаловать',
      new_to_aiko: 'Впервые в АЙКО?',
      sign_up: 'Зарегистрироваться',
      continue: 'Продолжать',
      forgot_passwword: 'Забыли пароль',
      reset_password:
        'Ссылка для сброса пароля будет отправлена на вашу электронную почту',
      sent_verification_code: 'Мы отправили код подтверждения на',
      enter_code: 'Пожалуйста, введите код',
      enter_password: 'Введите пароль',
      enter_confirm_password: 'Введите Подтвердить пароль',
      register: 'регистр',
      hello: 'Привет, давай знакомиться',
      password: 'Пароль',
      receive_invite: 'Вы получили приглашение от своего друга?',
      optional: 'Необязательный',
      delete: 'Удалить',
      comments: 'Комментарии',
      buy_plans: 'Купить планы',
      country: 'страна',
      more: 'более',
      chat: 'Чат',
      chatDetail: 'Личная переписка',
      commingSoon: 'Вскоре !!!',
      stories: 'Истории',
      success_to_add_story: 'Удалось добавить историю',
      saved_to_gallery: 'Сохранено в галерее.',
      add_story: 'Добавить историю',
      save_to_gallery: 'Сохранить в галерею',
      me: 'Мне',
      viking_helmet: 'Шлем викинга',
      stallone: 'Сталлоне',
      ping_pong: 'Настольный теннис',
      humanoid: 'Гуманоид',
      fire_effect: 'Эффект огня',
      emotion_meter: 'Измеритель эмоций',
      emotions_exaggerator: 'Преувеличитель эмоций',
      look1: 'Смотри1',
      look2: 'Смотри2',
      aviators: 'Авиаторы',
      ball_face: 'Шаровое лицо',
      beard: 'Борода',
      beauty: 'Красота',
      fairy_lights: 'Гирлянды',
      background_segmentation: 'Фоновая сегментация',
      hair_segmentation: 'Сегментация волос',
      flower_crown: 'Венок',
      frankenstein: 'Франкенштейн',
      lion: 'Лев',
      manly_face: 'мужественное лицо',
      plastic_ocean: 'Пластиковый океан',
      pumpkin: 'Тыква',
      scuba: 'Аквалангист',
      tape_face: 'Лента для лица',
      tiny_sunglasses: 'Крошечные солнцезащитные очки',
      topology: 'Топология',
      photo: 'Фото',
      video: 'видео',
      create_prompt_or_upload: 'Создать подсказку или загрузить',
      or: 'или',
      delete_story: 'Удалить историю',
      failed_to_get_stories: 'Не удалось получить истории',
      success_to_delete_story: 'Успешное удаление истории',
      create_background_image_with_prompt:
        'Создать фоновое изображение с помощью подсказки',
      prompt: 'быстрый',
      failed_to_get_all_supporters_by_user_id:
        'Не удалось получить всех сторонников по идентификатору пользователя.',
      failed_to_get_all_supportings_by_user_id:
        'Не удалось получить всю поддержку по идентификатору пользователя.',
      failed_to_search_users: 'Не удалось найти пользователей',
      failed_to_search_posts: 'Не удалось найти сообщения',
      share_as_post: 'Поделиться как публикацией',
      add_mentions: 'Добавить упоминания',
      sell_your_work: 'Продавайте свои работы',
      goods: 'Товары',
      price_msg: 'Пожалуйста, добавьте цену за это изображение',
      err_price_msg: 'Ошибка: пожалуйста, введите цену',
      buy: 'Покупать',
      register_card: 'Регистрационная карточка',
      input_card_information:
        'Пожалуйста, введите информацию о карте правильно.',
      register_card_info: 'Зарегистрируйте информацию о карте',
      ar_err: 'Скоро будет доступно',
      // 'В настоящее время AR поддерживает iOS 17.0. AR в настоящее время обновляется для iOS 17.0 и будет доступен в ближайшее время',
      create_story: 'Создать историю',
      use_ar: 'Используйте дополненную реальность',
      use_ai_video: 'Используйте AI-видео',
      for_story:
        'Чтобы создать историю, вы можете использовать AR или AI Video Generator. Пожалуйста, выберите один.',
      create_ai_video: 'Создать видео с искусственным интеллектом',
      failed_to_generate_Video: 'Не удалось создать видео.',
      use_ai_video_audio: 'Используйте танец ИИ',
      create_ai_video_audio: 'Создать видео из аудио',
      select_audio: 'Выберите аудиофайлы',
      select_audio_desc: 'Вы можете выбрать до 10 аудиофайлов.',
      input_prompt: 'Пожалуйста, введите приглашение для создания видео',
      generating_video: 'Создание видео...',
      generated_video_msg:
        'Сгенерированные видео добавлены в ваши истории. Пожалуйста, проверьте видео на странице историй.',
      create_more_files: 'Вы не можете создать еще 10 видео.',
      video_audio_popup:
        'Совет: идите и делайте свое дело! Мы сообщим вам, когда будут созданы ваши любимые музыкальные клипы!',
      create_after_msg:
        'идите и делайте свое дело! Мы сообщим вам, когда будут созданы ваши любимые музыкальные клипы!',
      ai_audio_video_prompt_tip:
        'Картина мотылька, Картина стрекозы-убийцы, Две рыбы разговаривают друг с другом в глубоком море. Картина мотылька,',
      audio_create_video: 'Создать видео',
      audio_trimming: 'Клип Аудио',
      choose_clip_duration: 'Выберите продолжительность клипа',
      generator_video: 'Генератор Видео',
      second: 'Секунды',
      spotify_home: 'Spotify Дом',
      your_playlist: 'Ваши плейлисты',
      add_to_playlist: 'Добавить в плейлист',
      expire_token: 'Срок действия токена доступа истек',
      search_music: 'Поиск музыки',
      music_name: 'Название музыки',
      status: 'Положение дел',
      completed: 'Завершенный',
      progressing: 'Прогресс',
      prompt1: 'Быстрый',
      music: 'Музыка',
      your_items: 'Ваши предметы',
      alert_uncomplete_video: 'Видео генерируется. Проверьте после создания.',
      success_to_update_operation: 'Добавлено в истории',
      success_to_delete_operation: 'Удалено видео',
      add_to_story: 'Добавить в истории',
      are_you_sure_you_want_to_delete_this_audio:
        'Вы уверены, что хотите удалить этот звук?',
      delete_video: 'Удалить видео',
      limit_downloading_cnt:
        'Вы не можете скачать более 3 файлов одновременно.',
      musics: 'Музыки',
      are_you_sure_want_to_delete : 'Уверен! Хочу удалить',
      database :  'База данных',
      no_keep_it :  'Нет, оставь',
      yes_delete_it : 'Да, удалить',
      is_invite : 'Приглашение вас стать соавтором их поста?',
      reject: 'Отвергать',
      accept: 'Принимать',
      my_profile : 'Мой профиль',
      stripe_payment : 'Оплата Stripe',
      please_input_all_informations : 'Пожалуйста, введите всю информацию.',
      there_is_no_token : 'Нет левой фишки. Пожалуйста, приобретите Токены.',
      edit: 'Редактировать',
      add: 'обавлять',
      profile_picture : 'Фото профиля',
      status_complete:  'Статус : Завершен',
      status_progressing :  'Статус : Прогрессирует',
      story: 'Рассказ',
      download: 'Загружать',
      Home: 'Дом',
      Search: 'Искать',
      Profile: 'Профиль',
      Setting: 'Оправа',
      ContactUs: 'Свяжитесь с нами',
      log_out: 'Выходить',
      dashboard: 'Щиток',
      update_profile: 'Обновить профиль',
      HealthCheck: 'Проверка здоровья',
      birthday: 'День рождения',
      Chat: 'Чат',
    },
  },
};
