import React, { createContext, useReducer } from 'react';
export const OpenAIContext = createContext();

const initialState = {
  urls: [],
  images: [],
  image: {},
  isLoading: false,
  hasErr: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'GENERATE_IMAGE': {
      const tmpUrls =
        action.payload.data &&
        action.payload.data.map((imageData) => {
          return imageData.url;
        });
      return {
        ...state,
        urls: tmpUrls,
        images: action.payload.data,
        hasErr: false,
      };
    }
    case 'HISTORY_IMAGES': {
      const tmpUrls =
        action.payload.data &&
        action.payload.data.map((imageData) => {
          return imageData.url;
        });
      return {
        ...state,
        urls: tmpUrls,
        images: action.payload.data,
        hasErr: false,
      };
    }
    case 'GET_IMAGE': {
      return {
        ...state,
        image: action.payload.data,
        hasErr: false,
      };
    }
    case 'ADD_COMMENT': {
      return {
        ...state,
        image: action.payload.data,
        hasErr: false,
      };
    }
    case 'SELECT_AUDIO': {
      return {
        ...state,
        selectAudio: action.payload.data,
        hasErr: false,
      };
    }
    case 'ERROR': {
      return { ...state, hasErr: true };
    }
    default: {
      return state;
    }
  }
}

export const OpenAIProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <OpenAIContext.Provider value={value}>{children}</OpenAIContext.Provider>
  );
};
