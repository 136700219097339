import React, { createContext, useReducer } from 'react';
export const NotificationsContext = createContext();

const initialState = {
  notifcations: [],
  invites: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_NOTIFICATIONS': {
      return {
        ...state,
        notifcations: action.payload.data,
        hasErr: false,
      };
    }
    case 'SET_INVITES': {
      return {
        ...state,
        invites: action.payload.data,
        hasErr: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
  );
};
