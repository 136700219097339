import Cookies from 'js-cookie';
import React, { createContext, useReducer } from 'react';

export const AdminContext = createContext();

const initialState = {
  adminInfo: Cookies.get('adminInfo')
    ? JSON.parse(Cookies.get('adminInfo'))
    : null,
  selectAudio: {
      trackId: '',
      playlistId: '',
      image: '',
      name: '',
      artistName: ''
    },
};

function reducer(state, action) {

  console.log('action type', action.type)
  switch (action.type) {
    case 'USER_LOGIN':
      return { ...state, adminInfo: action.payload };
    case 'USER_LOGOUT':
      return {
        ...state,
        adminInfo: null,
      };
    case 'UPDATE_USER': {
      console.log( 'reducer update user', action.payload.adminInfo );
      return {
        ...state,
        adminInfo: action.payload.adminInfo,
      };
    } 
    case 'USER_LOGGED_IN': {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLoggedIn: true,
      };
    }
    case 'USER_LOGGED_OUT': {
      return { ...state, user: {}, isLoggedIn: false };
    }
    case 'USER_REGISTER': {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLoggedIn: true,
      };
    }case 'GET_PROFILE': {
      return {
        ...state,
        profile: action.payload.data,
      };
    }
    case 'SUBSCRIBE_WEEKLY':
    case 'SUBSCRIBE_MONTHLY':
    case 'SUBSCRIBE_YEARLY':
    case 'PURCHASE_TOKENS':
    case 'USED_ONE_TOTKEN':
    case 'UNSUBSCRIBE': {
      return {
        ...state,
        user: action.payload.data,
      };
    }
    case 'SP_SELECT_AUDIO': {
      const _audio = {
        trackId: action.payload.trackId,
        playlistId: action.payload.playlistId,
        image: action.payload.image,
        name: action.payload.name,
        artistName: action.payload.artistName
      }
      console.log( 'audio', _audio);
      return {
        ...state,
        selectAudio: _audio,
      }
    }
    default:
      return state;
  }
}

export const AdminProvider = ({ children }) => {
  const [ state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};
