export default {
  translation: {
    all: {
      invite_msg: 'Agrega a tus amigas y consigue 5 generaciones gratis.',
      email: 'Correo electrónico',
      feed: 'Alimentar',
      welcome: 'Bienvenida',
      upload: 'Subir',
      nav_popup_msg: 'Haga clic para activar/desactivar la navegación',
      buy_plan: 'Comprar Planes',
      profile: 'Perfil',
      search: 'Buscar',
      create: 'Crear',
      feeds: 'Feeds',
      image_edit: 'Editar imagen',
      generate_edited_image: 'Generar imagen editada',
      edit_image: 'Editar imagen',
      cancel: 'Cancelar',
      failed_get_user: 'No se pudo obtener el usuario',
      failed_get_image: 'No se pudo obtener la imagen',
      you_already_liked_this_image: 'Ya te gustó esta imagen.',
      failed_to_set_like: 'Error al establecer Me gusta',
      invalid_password: 'Contraseña invalida',
      email_already_registered:
        'Correo electrónico ya registrado. Intente con otro correo electrónico.',
      failed_to_reset_password: 'No se pudo restablecer la contraseña',
      failed_to_get_user_profile: 'Error al obtener el perfil de usuario',
      failed_to_subscribe_yearly: 'No se pudo suscribir anualmente',
      failed_to_subscribe_monthly: 'No se pudo suscribir mensualmente',
      failed_to_subscribe_weekly: 'No se pudo suscribir semanalmente',
      failed_to_unsubscribe: 'No se pudo cancelar la suscripción',
      failed_to_purchase_tokens: 'Error al comprar tokens',
      failed_to_remove_one_token: 'No se pudo eliminar un token.',
      failed_to_get_all_categories: 'Error al obtener todas las categorías',
      failed_to_get_all_posts_on_search_page:
        'Error al obtener todas las publicaciones en la página de búsqueda',
      failed_to_get_posts: 'Error al obtener publicaciones',
      failed_to_get_all_posts_on_profile_page:
        'Error al obtener todas las publicaciones en la página de perfil',
      please_input_email_address:
        'Por favor, introduzca la dirección de correo electrónico.',
      error_occurred_during_sending_message_via_nodemailer:
        'Ocurrió un error durante el envío del mensaje a través de nodemailer',
      please_input_code: 'Por favor ingrese el código.',
      error_occurred_during_verify_code:
        'Ocurrió un error durante la verificación del código',
      success_to_reset_password: 'Éxito para restablecer la contraseña',
      error_occurred_during_reset_password:
        'Ocurrió un error durante el restablecimiento de la contraseña',
      please_input_name: 'Por favor ingrese el nombre',
      please_input_user_name: 'Por favor ingrese el nombre de usuario',
      please_input_email: 'Por favor ingrese el correo electrónico',
      invalidate_email: 'Correo electrónico invalidado, inténtalo de nuevo.',
      please_input_password: 'Por favor ingrese la contraseña',
      please_input_country: 'Ingrese el país',
      please_input_last_name: 'Por favor ingrese Apellido',
      failed_to_get_user_profile_by_id:
        'No se pudo obtener el perfil de usuario por id.',
      failed_to_get_one_post_by_id:
        'No se pudo obtener una publicación por id.',
      failed_to_get_all_posts_on_artwork:
        'Error al obtener todas las publicaciones sobre obras de arte',
      there_is_no_permission_to_edit_this_image:
        'No hay permiso para editar esta imagen.',
      failed_to_add_new_comment: 'Error al agregar un nuevo comentario.',
      failed_to_delete_post: 'No se pudo eliminar la publicación.',
      failed_to_get_post_on_comment_screen:
        'No se pudo obtener la publicación en la pantalla de comentarios',
      saved_image: 'Imagen guardada.',
      failed_to_get_openai_images: 'Error al obtener imágenes abiertas',
      please_input_prompt: 'Ingrese el aviso.',
      please_do_not_use_this_word: 'Por favor, no uses esta palabra.',
      confirm_your_selection: 'Confirme su selección.',
      please_select_image: 'Seleccione la imagen.',
      saved_selected_images: 'Imágenes seleccionadas guardadas.',
      for_all_editing_capabilities:
        'Para todas las capacidades de edición, obtenga tokens/suscripción.',
      please_select_category: 'Seleccione la categoría.',
      failed_to_send_invite: 'No se pudo enviar la invitación',
      failed_to_post: 'Error al publicar',
      failed_to_get_users: 'Error al obtener usuarios',
      failed_to_save_image: 'No se pudo guardar la imagen',
      already_added_user: 'Usuario ya agregada',
      failed_to_get_user_by_id_on_support:
        'No se pudo obtener el usuario por ID en el soporte',
      success_to_add_supporter: 'Éxito para agregar partidaria.',
      failed_to_set_supporter: 'No se pudo establecer el partidario',
      failed_to_delete_account: 'No se pudo eliminar la cuenta',
      please_input_customer_name_for_payment:
        'Ingrese el nombre del cliente para el pago.',
      your_order_is_confirmed: '¡Tu pedido está confirmado!',
      your_order_is_unsubscribed: '¡Tu pedido ha sido cancelado!',
      please_select_currency: 'Seleccione Moneda.',
      please_select_language: 'Seleccione el idioma.',
      please_input_fields: 'Por favor ingrese los campos.',
      please_mismatch_password: 'Por favor, no coincida con la contraseña.',
      please_input_your_name: 'Por favor ingrese su nombre.',
      please_input_subject: 'Por favor ingrese el asunto.',
      please_input_message: 'Por favor ingrese el mensaje.',
      success_to_sent_message: 'Éxito al mensaje enviado.',
      failed_to_sent_message: 'No se pudo enviar el mensaje.',
      failed_to_get_user: 'No se pudo obtener el usuario',
      creations_for_you: 'Creaciones, Para ti. Por ti.',
      global_generative_ai_Platform: 'Plataforma global de IA generativa',
      get_started: 'Empezar',
      try_pink_elephant:
        'Prueba... Elefante rosa bailando sobre una pelota en la montaña.',
      artwork: 'Obra de arte',
      category: 'Categoría',
      discover: 'Descubrir',
      search_users: 'Buscar usuarias, fotos ...',
      posts: 'Publicaciones',
      supporters: 'Partidarias',
      supporting: 'Secundaria',
      bio: 'biografía',
      gallery: 'Galería',
      collaborations: 'Colaboraciones',
      settings: 'Ajustes',
      tokens_left: 'FICHAS QUE QUEDAN',
      account_settings: 'Configuraciones de la cuenta',
      privacy_policy: 'Política de privacidad',
      terms_of_use: 'Condiciones de uso',
      leaderboard: 'Tabla de clasificación',
      contact_us: 'Contacta con nosotras',
      logout: 'Cerrar sesión',
      edit_profile: 'Editar perfil',
      change_password: 'Cambiar la contraseña',
      languages: 'Idiomas',
      currency: 'Divisa',
      delete_account: 'Borrar cuenta',
      tip_msg:
        'Cuando elimines tu cuenta se borrarán todos tus datos y herramientas de mindfulness',
      are_you_sure_you_want_to_delete_your_account:
        '¿Estás segura de que quieres eliminar tu cuenta?',
      name: 'Nombre',
      user_name: 'Nombre de usuario',
      username: 'Nombre de usuario',
      save_changes: 'Guardar cambios',
      current_password: 'Contraseña actual',
      new_password: 'Nueva contraseña',
      confirm_new_password: 'Confirmar nueva contraseña',
      save_new_password: 'Guardar nueva contraseña',
      choose_languages: 'Elija Idiomas',
      select_option: 'Seleccionar opción',
      save_language: 'Guardar idioma',
      choose_currency: 'Elegir Moneda',
      select_currency: 'Seleccione el tipo de moneda',
      subject: 'Sujeta',
      message: 'Mensaje',
      send: 'Enviar',
      press_and_speak_your_prompt: '¡Presiona y di tu aviso!',
      edit_your_photo_further: '¡Edita tu foto aún más! ¡Colabora también!',
      image_saves_to_your_gallery_when_you_post:
        '¡La imagen se guarda en tu galería cuando publicas!',
      make_more_from_your_words_and_search_for_users_and_other_images:
        '¡Haz más con tus palabras y busca usuarios y otras imágenes!',
      upload_photos_from_your_gallery_and_edit_them:
        'Sube fotos de tu galería y edítalas',
      check_your_notifications_here: 'Consulta aquí tus Notificaciones',
      press_to_support_and_collaborate: 'Presiona para apoyar y colaborar',
      subscriptions: 'Suscripciones',
      tokens: 'fichas',
      view_details: 'Ver detalles',
      monthly: 'Mensual',
      Weekly: 'Semanalmente',
      Yearly: 'Anual',
      most_popular: 'Más popular',
      upgrade_plan: 'Plan de actualización',
      buy_now: 'Comprar ahora',
      plan_details: 'Detalles del plan',
      image_generator: 'Generador de imágenes',
      unlimited_editing_tools: 'Herramientas de edición ilimitadas',
      full_collaboration_capabilities: 'Capacidades de colaboración completa',
      title: 'Título',
      created_at: 'Creado en',
      comment: 'Comentario',
      share_image: 'Compartir imagen',
      share_image_facebook: 'Compartir imagen facebook, Instagram, Pinterest',
      download_image: 'Descargar imagen',
      related_artwork: 'Ilustraciones relacionadas',
      no_creations_yet: 'Aún no hay creaciones',
      turn_off_commenting: 'desactivar los comentarios',
      invite_collaborations: 'Invitar colaboraciones',
      categories: 'Categorías',
      hide_prompt: 'Ocultar solicitud',
      err_phone_msg:
        'error: ingrese el correo electrónico o el número de teléfono de su amigo.',
      phone_number: 'Número de teléfono',
      buy_more:
        'Compre más tokens/Suscríbase para continuar generando imágenes',
      ok: 'DE ACUERDO',
      generated: 'Generada',
      view_variation: 'Ver variaciones',
      variations: 'variaciones',
      variation_msg:
        'Puede seleccionar entre estas variaciones o puede seleccionar la generación anterior',
      new_category: 'Nueva categoría',
      collaborate: 'Colaborar',
      place_search_user: 'Buscar usuarias',
      new_post: 'Nueva publicación',
      write_caption: 'Escribir título...',
      peoples: 'Pueblos',
      access_collaboration:
        '¡Acceda a la colaboración apoyando a otros y haciendo que ellos lo apoyen a usted!',
      artworks: 'Obras de arte',
      users: 'Usuarias',
      notifications: 'Notificaciones',
      success: 'Éxito',
      error_code: 'Código de error',
      unsubscribe: 'Darse de baja',
      connecting_appstore:
        'Conectando la tienda de aplicaciones, espere un segundo y vuelva a intentarlo',
      purchased: 'Comprada',
      save: 'Ahorrar',
      only: 'Solo',
      your_name: 'Su nombre',
      liked_images: 'Imágenes que me gustaron',
      support: 'Apoyo',
      weekly_plan_details: 'Detalles del plan semanal',
      monthly_plan_details: 'Detalles del plan mensual',
      yearly_plan_details: 'Detalles del plan anual',
      logo_tip:
        'Consejo: use el botón con el logotipo de AIKO en la parte inferior de la pantalla para navegar.',
      got_it: 'OK. entiendo',
      instructions: 'INSTRUCCIONES',
      no_bullying: 'NO Intimidación / Acoso',
      no_nudity: 'SIN desnudez/contenido sexual',
      young_visitors: '(Tenemos visitantes jóvenes)',
      no_political: 'NO Campañas Políticas',
      only_love: 'Solo amor, creación y colaboración',
      learn_more: 'APRENDE MÁS',
      login: 'Acceso',
      welcome_back: 'Bienvenido de nuevo',
      new_to_aiko: 'Nueva en AIKO?',
      sign_up: 'Inscribirse',
      continue: 'Continuar',
      forgot_passwword: 'Has olvidado tu contraseña',
      reset_password:
        'El enlace para restablecer la contraseña se enviará a su correo electrónico',
      sent_verification_code: 'Hemos enviado el código de verificación a',
      enter_code: 'Por favor ingrese el código',
      enter_password: 'Introducir la contraseña',
      enter_confirm_password: 'Ingresar Confirmar Contraseña',
      register: 'Registro',
      hello: 'Hola, vamos a conocernos',
      password: 'Contraseña',
      receive_invite: '¿Recibiste la invitación de tu amiga?',
      optional: 'Opcional',
      delete: 'Borrar',
      comments: 'Comentarios',
      buy_plans: 'Comprar Planes',
      country: 'país',
      more: 'más',
      chat: 'Charlar',
      chatDetail: 'Chat privado',
      commingSoon: 'Muy pronto !!!',
      stories: 'Cuentos',
      success_to_add_story: 'Éxito para agregar historia.',
      saved_to_gallery: 'Guardado en la galería',
      add_story: 'Agregar historia',
      save_to_gallery: 'Guardar en la galería',
      me: 'A mí',
      viking_helmet: 'Casco vikingo',
      stallone: 'Stallone',
      ping_pong: 'Ping Pong',
      humanoid: 'humanoide',
      fire_effect: 'Efecto fuego',
      emotion_meter: 'Medidor de emociones',
      emotions_exaggerator: 'Exagerador de emociones',
      look1: 'Mira1',
      look2: 'Mira2',
      aviators: 'Aviadores',
      ball_face: 'cara de bola',
      beard: 'Barba',
      beauty: 'Belleza',
      fairy_lights: 'Luces de hadas',
      background_segmentation: 'Segmentación de fondo',
      hair_segmentation: 'Segmentación del cabello',
      flower_crown: 'Corona de flores',
      frankenstein: 'frankenstein',
      lion: 'León',
      manly_face: 'Cara varonil',
      plastic_ocean: 'Océano de plástico',
      pumpkin: 'Calabaza',
      scuba: 'Buzo',
      tape_face: 'Cara de cinta',
      tiny_sunglasses: 'Pequeñas gafas de sol',
      topology: 'Topología',
      photo: 'foto',
      video: 'video',
      create_prompt_or_upload: 'Crear mensaje o cargar',
      or: 'o',
      delete_story: 'Eliminar historia',
      failed_to_get_stories: 'No se pudieron obtener historias',
      success_to_delete_story: 'Éxito para eliminar la historia.',
      create_background_image_with_prompt: 'Crear imagen de fondo con aviso',
      prompt: 'inmediato',
      failed_to_get_all_supporters_by_user_id:
        'No se pudieron obtener todos los seguidores por ID de usuario',
      failed_to_get_all_supportings_by_user_id:
        'No se pudieron obtener todos los soportes por identificación de usuario',
      failed_to_search_users: 'No se han podido buscar usuarios',
      failed_to_search_posts: 'No se pudieron buscar publicaciones',
      share_as_post: 'Compartir como publicación',
      add_mentions: 'Agregar menciones',
      sell_your_work: 'Vende tu trabajo',
      goods: 'Bienes',
      price_msg: 'Por favor, agregue el precio de esta imagen',
      err_price_msg: 'Error: Por favor, introduzca el precio',
      buy: 'Comprar',
      register_card: 'Tarjeta de registro',
      input_card_information:
        'Por favor, introduzca la información de la tarjeta correctamente.',
      register_card_info: 'Información de la tarjeta de registro',
      ar_err: 'Disponible próximamente',
      // 'AR actualmente es compatible con iOS 17.0. AR se está actualizando actualmente para iOS 17.0 y estará disponible pronto',
      create_story: 'Crear historia',
      use_ar: 'Usar AR',
      use_ai_video: 'Utilice vídeo con AI',
      for_story:
        'Para crear una historia, puede utilizar AR o AI Video Generator. Por favor elige uno.',
      create_ai_video: 'Crear vídeo con AI',
      failed_to_generate_Video: 'No se pudo generar el video',

      use_ai_video_audio: 'Usa baile AI',
      create_ai_video_audio: 'Crear vídeo a partir de audio',
      select_audio: 'Seleccionar archivos de audio',
      select_audio_desc: 'Puede seleccionar hasta 10 archivos de audio.',
      input_prompt: 'Por favor ingrese el mensaje para crear video',
      generating_video: 'Generando vídeos...',
      generated_video_msg:
        'Vídeos generados agregados en tus historias. Consulte los videos en la página de historias.',
      create_more_files: 'No puedes generar 10 videos más.',
      video_audio_popup:
        'Consejo: ¡Ve a hacer lo tuyo! ¡Te avisaremos cuando se hayan generado tus vídeos musicales favoritos!',
      create_after_msg:
        '¡Ve a hacer lo tuyo! ¡Te avisaremos cuando se hayan generado tus vídeos musicales favoritos!',
      ai_audio_video_prompt_tip:
        'Una pintura de una polilla, Una pintura de una libélula asesina, Dos peces hablando entre sí en las profundidades del mar, Una pintura de una polilla,',
      audio_create_video: 'Crear vídeo',
      audio_trimming: 'clips de audio',
      choose_clip_duration: 'Elija la duración del clip',
      generator_video: 'Vídeo generador',
      second: 'Segundos',
      spotify_home: 'Spotify Hogar',
      your_playlist: 'Tus listas de reproducción',
      add_to_playlist: 'Agregar a la lista de reproducción',
      expire_token: 'El token de acceso expiró',
      search_music: 'Buscar música',
      music_name: 'Nombre de la música',
      status: 'Estado',
      completed: 'Terminado',
      progressing: 'Progresión',
      prompt1: 'Inmediato',
      music: 'Música',
      your_items: 'Tus cosas',
      alert_uncomplete_video:
        'El video se está generando. Verifique después de generarlo.',
      success_to_update_operation: 'Agregado a historias',
      success_to_delete_operation: 'Vídeo eliminado',
      add_to_story: 'Añadir a historias',
      are_you_sure_you_want_to_delete_this_audio:
        '¿Estás seguro de que deseas eliminar este audio?',
      delete_video: 'Eliminar vídeo',
      limit_downloading_cnt:
        'No puedes descargar más de 3 archivos al mismo tiempo.',
        musics: 'Músicas',
      are_you_sure_want_to_delete : '¡Estás seguro! Desea eliminar',
      database :  'Base de datos',
      no_keep_it :  'No, guárdalo',
      yes_delete_it : 'Sí, elimínalo',
      is_invite : '¿Te está invitando a ser colaborador en su publicación?',
      reject: 'Rechazar',
      accept: 'Aceptar',
      my_profile : 'Mi Perfil',
      stripe_payment : 'Pago con Stripe',
      please_input_all_informations : 'Por favor, introduzca toda la información.',
      there_is_no_token : 'No queda ninguna ficha. Por favor, compra fichas.',
      edit: 'Editar',
      add: 'Agregar',
      profile_picture : 'Foto de perfil',
      status_complete:  'Estado : Completado',
      status_progressing :  'Estado : Progresando',
      story: 'Cuento',
      download: 'Descargar',
      Home: 'Hogar',
      Search: 'Buscar',
      Profile: 'Perfil',
      Setting: 'Ajuste',
      ContactUs: 'Contacta con nosotros',
      log_out: 'Cerrar sesión',
      dashboard: 'Salpicadero',
      update_profile: 'Actualizar perfil',
      HealthCheck: 'Chequeo de salud',
      birthday: 'Cumpleaños',
      Chat: 'Charlar',
    },
  },
};
