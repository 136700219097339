import React, { createContext, useReducer } from 'react';
export const SpotifyContext = createContext();

const initialState = {
  auth: {
    token: '',
  },
  updateUser: {
    id: '',
    images: [
      {
        url: '',
      },
    ],
    display_name: '',
    followers: {
      total: 0,
    },
  },
  inputCreatePlaylist: {
    value: '',
  },
  selectAudio: {
    id: '',
    full_title: '',
    thumnail: '',
    preview: '',
  },
  clipInfo: {
    start: 0,
    end: 0,
  },
  operation: {
    _id: '',
    userId: '',
    audioName: '',
    originAudio: '',
    trimAudio: '',
    videoUrl: '',
    replicateUrl: '',
    prompt: '',
    thumbnail: '',
    addedStory: false,
    created: false,
    timestamp: 0,
  },
  updatePlaylists: [],
  detailPlaylists: {
    id: '',
    description: '',
    images: [
      {
        url: '',
      },
    ],
    name: '',
    owner: {
      display_name: '',
    },
  },
  snapshotUpdate: {
    snapshot_id: '',
  },
};

function reducer(state, action) {
  console.log( 'called spotify context')
  switch (action.type) {
    case 'SP_AUTH': {
      console.log( 'called this');
      const _auth = {
        token: action.payload.token,
      };
      return {
        ...state,
        auth: _auth,
      };
    }
    case 'SP_UPDATE_USER': {
      const _updateUser = {
        id: action.payload.id,
        display_name: action.payload.display_name,
        images: action.payload.images,
        followers: action.payload.followers,
      };
      return {
        ...state,
        updateUser: _updateUser,
      };
    }
    case 'SP_INPUT_CREATE_PLAYLIST': {
      const _inputCreatePlaylist = {
        value: action.payload.value,
      };
      return {
        ...state,
        inputCreatePlaylist: _inputCreatePlaylist,
      };
    }
    case 'SP_UPDATE_PLAYLISTS': {
      const _updatePlaylists = action.payload;
      return { ...state, updatePlaylists: _updatePlaylists };
    }
    case 'SP_DETAIL_PLAYLISTS': {
      const _detailPlaylists = {
        id: action.payload.id,
        description: action.payload.description,
        images: action.payload.images,
        name: action.payload.name,
        owner: {
          display_name: action.payload.owner.display_name,
        },
      };
      return {
        ...state,
        detailPlaylists: _detailPlaylists,
      };
    }
    case 'SP_SNAPSHOT_UPDATE': {
      const _snapShot = {
        snapshot_id: action.payload.snapshot_id,
      };
      return {
        ...state,
        snapshotUpdateType: _snapShot,
      };
    }
    case 'SP_SELECT_AUDIO': {
      const _audio = {
        id: action.payload.id,
        full_title: action.payload.full_title,
        thumnail: action.payload.thumnail,
        preview: action.payload.preview,
      }
      console.log( 'audio', _audio);
      return {
        ...state,
        selectAudio: _audio,
      }
    }
    case 'SP_CLIP_INFO': {
      const _clip = {
        start: action.payload.start,
        end: action.payload.end,
      }
      return {
        ...state,
        clipInfo: _clip,
      }
    }
    case 'SP_OPERATION' : {
      const _operation = {
        _id: action.payload._id,
        userId: action.payload.userId,
        audioName: action.payload.audioName,
        originAudio: action.payload.originAudio,
        trimAudio: action.payload.trimAudio,
        videoUrl: action.payload.videoUrl,
        replicateUrl: action.payload.replicateUrl,
        prompt: action.payload.prompt,
        thumbnail: action.payload.thumbnail,
        addedStory: action.payload.addedStory,
        created: action.payload.created,
        timestamp: action.payload.timestamp,
      }
      return {
        ...state,
        operation: _operation,
      }
    }
    default: {
      return state;
    }
  }
}

export const SpotifyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <SpotifyContext.Provider value={value}>{children}</SpotifyContext.Provider>
  );
};
