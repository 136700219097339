import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer } from './utils/toast';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import './i18n'; // here is the i18n config file added
import PrivateRoute from './components/login/PrivateRoute';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";         
//core
import "primereact/resources/primereact.min.css";   
import AdsComponent from './components/AdsComponent';

const Layout = lazy(() => import('./layout/Layout'));
const Splash = lazy(() => import('./pages/Splash'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const Terms  = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Contact = lazy(() => import('./pages/Contact'));
const About  = lazy(() => import('./pages/About'));
const Payment = lazy(() => import('./pages/StripeMobile'));
const ForgetPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const App = () => {
  return (
    <>
      <AdsComponent dataAdSlot='X2XXXXXXXX' />
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/splash" component={Splash} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          {/* <Route path="/login" component={Login} /> */}
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/payment" component={Payment} />

          <PrivateRoute>
            {' '}
            <Route path="/" component={Layout} />
          </PrivateRoute>
          <Redirect exact from="/" to="/splash" />
        </Switch>
      </Router>
    </>
  );
};

export default App;
