export default {
  translation: {
    all: {
      invite_msg: '添加您的好友，即可获得5代免费。',
      email: '电子邮件',
      feed: '供给',
      welcome: '欢迎',
      upload: '上传',
      nav_popup_msg: '单击以打开/关闭导航',
      buy_plan: '购买计划',
      profile: '轮廓',
      search: '搜索',
      create: '创建',
      feeds: '供给',
      image_edit: '图像编辑',
      generate_edited_image: '生成已编辑的图像',
      edit_image: '编辑图像',
      cancel: '取消',
      failed_get_user: '无法获取用户',
      failed_get_image: '无法获取图像',
      you_already_liked_this_image: '你已经喜欢上了这张照片。',
      failed_to_set_like: '无法设置“赞”',
      invalid_password: '密码无效',
      email_already_registered: '电子邮件已注册。请重新发送电子邮件。',
      failed_to_reset_password: '重置密码失败',
      failed_to_get_user_profile: '无法获取用户配置文件',
      failed_to_subscribe_yearly: '无法每年订阅',
      failed_to_subscribe_monthly: '无法按月订阅',
      failed_to_subscribe_weekly: '无法每周订阅',
      failed_to_unsubscribe: '取消订阅失败',
      failed_to_purchase_tokens: '无法购买代币',
      failed_to_remove_one_token: '无法删除一个令牌。',
      failed_to_get_all_categories: '无法获取所有类别',
      failed_to_get_all_posts_on_search_page: '无法获取搜索页面上的所有帖子',
      failed_to_get_posts: '无法获取帖子',
      failed_to_get_all_posts_on_profile_page:
        '无法获取配置文件页面上的所有帖子',
      please_input_email_address: '请输入电子邮件地址。',
      error_occurred_during_sending_message_via_nodemailer:
        '通过nodemailer发送消息时出错',
      please_input_code: '请输入代码。',
      error_occurred_during_verify_code: '验证代码期间出错',
      success_to_reset_password: '重置密码成功',
      error_occurred_during_reset_password: '重置密码时出错',
      please_input_name: '请输入名称',
      please_input_user_name: '请输入用户名',
      please_input_email: '请输入电子邮件',
      invalidate_email: '使电子邮件无效，请重试。',
      please_input_password: '请输入密码',
      please_input_country: '请输入国家',
      please_input_last_name: '请输入姓氏',
      failed_to_get_user_profile_by_id: '无法按id获取用户配置文件',
      failed_to_get_one_post_by_id: '无法按id获取一个帖子',
      failed_to_get_all_posts_on_artwork: '无法获取有关艺术作品的所有帖子',
      there_is_no_permission_to_edit_this_image: '没有编辑此图像的权限。',
      failed_to_add_new_comment: '添加新评论失败。',
      failed_to_delete_post: '未能删除帖子。',
      failed_to_get_post_on_comment_screen: '无法在评论屏幕上获取帖子',
      saved_image: '保存图像',
      failed_to_get_openai_images: '无法获取openai图像',
      please_input_prompt: '请输入提示。',
      please_do_not_use_this_word: '请不要用这个词',
      confirm_your_selection: '确认您的选择。',
      please_select_image: '请选择图像。',
      saved_selected_images: '已保存选定的图像。',
      for_all_editing_capabilities: '对于所有编辑功能，请获取令牌/订阅。',
      please_select_category: '请选择类别。',
      failed_to_send_invite: '发送要求失败',
      failed_to_post: '无法发布',
      failed_to_get_users: '无法获取用户',
      failed_to_save_image: '保存图像失败',
      already_added_user: '已添加用户',
      failed_to_get_user_by_id_on_support: '无法通过支持id获取用户',
      success_to_add_supporter: '成功添加支持者。',
      failed_to_set_supporter: '设置支持者失败',
      failed_to_delete_account: '删除帐户失败',
      please_input_customer_name_for_payment: '请输入付款的客户名称。',
      your_order_is_confirmed: '您的订单已确认！',
      your_order_is_unsubscribed: '您的订单已取消订阅！',
      please_select_currency: '请选择“货币”。',
      please_select_language: '请选择语言。',
      please_input_fields: '请输入字段。',
      please_mismatch_password: '密码不匹配。',
      please_input_your_name: '请输入您的姓名。',
      please_input_subject: '请输入主题。',
      please_input_message: '请输入消息。',
      success_to_sent_message: '发送消息成功。',
      failed_to_sent_message: '无法发送消息。',
      failed_to_get_user: '无法获取用户',
      creations_for_you: '创意，为你。由你。',
      global_generative_ai_Platform: '全球生成AI平台',
      get_started: '开始',
      try_pink_elephant: '尝试粉红色的大象在山上的球上跳舞。',
      artwork: '艺术品',
      category: '类别',
      discover: '发现',
      search_users: '搜索用户，图片。。。',
      posts: '贴子',
      supporters: '支持者',
      supporting: '支持的',
      bio: 'Bio',
      gallery: '画廊',
      collaborations: '合作者',
      settings: '设置',
      tokens_left: '剩余代币',
      account_settings: '帐户设置',
      privacy_policy: '隐私政策',
      terms_of_use: '使用条款',
      leaderboard: '排行榜',
      contact_us: '联系我们',
      logout: '注销',
      edit_profile: '编辑配置文件',
      change_password: '更改密码',
      languages: '语言',
      currency: '货币',
      delete_account: '删除帐户',
      tip_msg: '当你删除你的账户时，你的所有数据和正念工具都将被删除',
      are_you_sure_you_want_to_delete_your_account: '你确定要删除你的帐户吗？',
      name: '名称',
      user_name: '用户名',
      username: '用户名',
      save_changes: '保存更改',
      current_password: '密码',
      new_password: '新密码',
      confirm_new_password: '确认新密码',
      save_new_password: '保存新密码',
      choose_languages: '选择语言',
      select_option: '选择选项',
      save_language: '保存语言',
      choose_currency: '选择货币',
      select_currency: '选择货币',
      subject: '主题',
      message: '消息',
      send: '发送',
      press_and_speak_your_prompt: '按下并说出提示！',
      edit_your_photo_further: '编辑您的照片！也要合作！',
      image_saves_to_your_gallery_when_you_post:
        '当你发帖时，图片会保存到你的图库中！',
      make_more_from_your_words_and_search_for_users_and_other_images:
        '从你的文字和搜索用户和其他图片中获得更多！',
      upload_photos_from_your_gallery_and_edit_them:
        '从您的图库上传照片并进行编辑',
      check_your_notifications_here: '在此处查看您的通知',
      press_to_support_and_collaborate: '施压支持和合作',
      subscriptions: '订阅',
      tokens: '令牌',
      view_details: '查看详细信息',
      monthly: '月刊',
      Weekly: '每周的',
      Yearly: '每年的',
      most_popular: '最受欢迎',
      upgrade_plan: '升级计划',
      buy_now: '立即购买',
      plan_details: '计划详细信息',
      image_generator: '图像生成',
      unlimited_editing_tools: '无限编辑工具',
      full_collaboration_capabilities: '全面协作能力',
      title: '名称',
      created_at: '创建时间',
      comment: '评论',
      share_image: '共享图像',
      share_image_facebook: '将图片分享到facebook、Instagram、Pinterest',
      download_image: '下载图像',
      related_artwork: '相关艺术品',
      no_creations_yet: '还没有创作',
      turn_off_commenting: '关闭评论',
      invite_collaborations: '邀请合作',
      categories: '类别',
      hide_prompt: '隐藏提示',
      err_phone_msg: '错误：请输入您朋友的电子邮件或电话号码。',
      phone_number: '电话号码',
      buy_more: '购买更多代币/订阅以继续生成图像',
      ok: '好',
      generated: '生成',
      view_variation: '查看变体',
      variations: '变体',
      variation_msg: '您可以从这些变体中进行选择，也可以选择上一代',
      new_category: '新类别',
      collaborate: '合作',
      place_search_user: '搜索用户',
      new_post: '最新帖子',
      write_caption: '写标题...',
      peoples: '人们',
      access_collaboration: '通过支持他人并让他们支持您来获得协作！',
      artworks: '艺术品',
      users: '用户',
      notifications: '通知',
      success: '成功',
      error_code: '错误代码',
      unsubscribe: '退订',
      connecting_appstore: '正在连接应用商店，请稍等再试',
      purchased: '已购买',
      save: '保存',
      only: '仅有的',
      your_name: '你的名字',
      liked_images: '喜欢的图片',
      support: '支持',
      weekly_plan_details: '每周计划详情',
      monthly_plan_details: '每月计划详情',
      yearly_plan_details: '每年计划详情',
      logo_tip: '提示：使用屏幕底部的 AIKO 徽标按钮进行导航。',
      got_it: '好的。 知道了',
      instructions: '指示',
      no_bullying: '禁止欺凌/骚扰',
      no_nudity: '禁止裸露/色情内容',
      young_visitors: '（我们有年轻的访客）',
      no_political: '没有政治运动',
      only_love: '只有爱、创造和协作',
      learn_more: '了解更多',
      login: '登录',
      welcome_back: '欢迎回来',
      new_to_aiko: 'AIKO 新手？',
      sign_up: '登记',
      continue: '继续',
      forgot_passwword: '忘记密码',
      reset_password: '重置密码链接将发送到您的电子邮件',
      sent_verification_code: '我们已将验证码发送至',
      enter_code: '请输入代码',
      enter_password: '输入密码',
      enter_confirm_password: '输入确认密码',
      register: '登记',
      hello: '你好，互相认识一下',
      password: '密码',
      receive_invite: '你收到朋友的邀请了吗？',
      optional: '选修的',
      delete: '删除',
      comments: '评论',
      buy_plans: '购买计划',
      country: '国家',
      more: '更多',
      chat: '聊天',
      chatDetail: '私人聊天',
      commingSoon: '即将推出 !!!',
      stories: '故事',
      success_to_add_story: '添加故事成功',
      saved_to_gallery: '保存到图库',
      add_story: '添加故事',
      save_to_gallery: '保存到图库',
      me: '我',
      viking_helmet: '维京头盔',
      stallone: '史泰龙',
      ping_pong: '乒乓',
      humanoid: '人形',
      fire_effect: '火焰效果',
      emotion_meter: '情绪测量仪',
      emotions_exaggerator: '情绪夸张者',
      look1: '外观1',
      look2: '外观2',
      aviators: '飞行员',
      ball_face: '球面',
      beard: '胡须',
      beauty: '美丽',
      fairy_lights: '仙女灯',
      background_segmentation: '背景分割',
      hair_segmentation: '头发分割',
      flower_crown: '花冠',
      frankenstein: '弗兰肯斯坦',
      lion: '狮子',
      manly_face: '男子气概的脸',
      plastic_ocean: '塑料海洋',
      pumpkin: '南瓜',
      scuba: '潜水员',
      tape_face: '胶带面',
      tiny_sunglasses: '小太阳镜',
      topology: '拓扑结构',
      photo: '照片',
      video: '视频',
      create_prompt_or_upload: '创建提示或上传',
      or: '或者',
      delete_story: '删除故事',
      failed_to_get_stories: '获取故事失败',
      success_to_delete_story: '删除故事成功',
      create_background_image_with_prompt: '使用提示创建背景图像',
      prompt: '迅速的',
      failed_to_get_all_supporters_by_user_id: '无法通过用户 ID 获取所有支持者',
      failed_to_get_all_supportings_by_user_id: '无法通过用户 ID 获取所有支持',
      failed_to_search_users: '搜索用户失败',
      failed_to_search_posts: '搜索帖子失败',
      share_as_post: '分享为帖子',
      add_mentions: '添加提及',
      sell_your_work: '出售你的作品',
      goods: '商品',
      price_msg: '请添加此图像的价格',
      err_price_msg: '错误：请输入价格',
      buy: '购买',
      register_card_info: '登记卡',
      ar_err: '即将推出',
      //  'AR目前支持iOS 17.0。AR 目前正在针对 iOS 17.0 进行更新，即将推出',
      create_story: '创建故事',
      use_ar: '使用增强现实',
      use_ai_video: '使用AI智能视频',
      for_story: '要创建故事，您可以使用 AR 或 AI 视频生成器。 请选择一个',
      create_ai_video: '创建AI智能视频',
      failed_to_generate_Video: '生成视频失败',
      use_ai_video_audio: '使用AI舞蹈',
      create_ai_video_audio: '从音频创建视频',
      select_audio: '选择音频文件',
      select_audio_desc: '您最多可以选择 10 个音频文件。',
      input_prompt: '请输入提示以创建视频',
      generating_video: '正在生成视频...',
      generated_video_msg:
        '生成的视频添加到您的故事中。 请查看故事页面上的视频',
      create_more_files: '您无法再生成 10 个视频',
      video_audio_popup:
        '提示：去做你的事吧！ 当您最喜爱的音乐视频生成时，我们会通知您',
      create_after_msg:
        '去做你的事吧！ 当您最喜爱的音乐视频生成时，我们会通知您',
      ai_audio_video_prompt_tip:
        '一幅飞蛾的画，一幅杀手蜻蜓的画，两条鱼在深海里互相交谈，一幅飞蛾的画，',
      audio_create_video: '创建视频',
      audio_trimming: '剪辑音频',
      choose_clip_duration: '选择剪辑持续时间',
      generator_video: '创建视频',
      second: '秒数',
      spotify_home: 'Spotify 主页',
      your_playlist: '您的播放列表',
      add_to_playlist: '添加到播放列表',
      expire_token: '访问令牌已过期',
      search_music: '搜索音乐',
      music_name: '音乐名称',
      status: '地位',
      completed: '完全的',
      progressing: '进展',
      prompt1: '迅速的',
      music: '音乐',
      your_items: '您的物品',
      alert_uncomplete_video: '视频正在生成，生成后请检查。',
      success_to_update_operation: '添加到故事',
      success_to_delete_operation: '已删除视频',
      add_to_story: '添加到故事',
      are_you_sure_you_want_to_delete_this_audio: '您确定要删除该音频吗？',
      delete_video: '删除视频',
      limit_downloading_cnt: '您不能同时下载超过 3 个文件。',
      musics: '音乐',
      are_you_sure_want_to_delete : '是否确定！想要删除',
      database :  '数据库',
      no_keep_it :  '不，保留它',
      yes_delete_it : '是的，删除它',
      is_invite : '邀请您成为他们帖子的合作者吗',
      reject: '拒绝',
      accept: '接受',
      my_profile : '我的个人资料',
      stripe_payment : '条纹支付',
      please_input_all_informations : '请输入所有信息。',
      there_is_no_token : '没有左令牌。请购买代币。',
      edit: '编辑',
      add: '加',
      profile_picture : '资料图片',
      status_complete:  '状态 ： 已完成',
      status_progressing :  '状态 ： 进行中',
      story: '故事',
      download: '下载',
      Home: '家',
      Search: '搜索',
      Profile: '轮廓',
      Setting: '设置',
      ContactUs: '联系我们',
      log_out: '登出',
      dashboard: '挡泥板',
      update_profile: '更新个人资料',
      HealthCheck: '健康检查',
      birthday: '生日',
      Chat: '聊天',
    },
  },
};
