export default {
  translation: {
    all: {
      invite_msg: 'Ajoutez vos amis et obtenez 5 générations gratuites.',
      email: 'E-mail',
      feed: 'Alimentation',
      welcome: 'Bienvenue',
      upload: 'Télécharger',
      nav_popup_msg: 'Cliquez pour activer/désactiver la navigation',
      buy_plan: 'Купить планы',
      profile: 'Profil',
      search: 'Recherche',
      create: 'Créer',
      feeds: 'Flux',
      image_edit: "Modifier l'image",
      generate_edited_image: 'Générer une image modifiée',
      edit_image: "Éditer l'image",
      cancel: 'Annuler',
      failed_get_user: "Impossible d'obtenir l'utilisateur",
      failed_get_image: "Impossible d'obtenir l'image",
      you_already_liked_this_image: 'Vous avez déjà aimé cette image.',
      failed_to_set_like: "Échec de la définition de J'aime",
      invalid_password: 'Mot de passe incorrect',
      email_already_registered:
        'E-mail déjà enregistré. Veuillez essayer un autre e-mail.',
      failed_to_reset_password: 'Échec de la réinitialisation du mot de passe',
      failed_to_get_user_profile: "Impossible d'obtenir le profil utilisateur",
      failed_to_subscribe_yearly: "Échec de l'abonnement annuel",
      failed_to_subscribe_monthly: "Échec de l'abonnement mensuel",
      failed_to_subscribe_weekly: "Échec de l'abonnement hebdomadaire",
      failed_to_unsubscribe: 'Échec de la désinscription',
      failed_to_purchase_tokens: "Impossible d'acheter des jetons",
      failed_to_remove_one_token: "Échec de la suppression d'un jeton.",
      failed_to_get_all_categories:
        "Impossible d'obtenir toutes les catégories",
      failed_to_get_all_posts_on_search_page:
        "Impossible d'obtenir tous les messages sur la page de recherche",
      failed_to_get_posts: 'Échec de la récupération des messages',
      failed_to_get_all_posts_on_profile_page:
        "Impossible d'obtenir tous les messages sur la page de profil",
      please_input_email_address: 'Please input email address.',
      error_occurred_during_sending_message_via_nodemailer:
        'Error occurred during sending message via nodemailer',
      please_input_code: 'Veuillez saisir le code.',
      error_occurred_during_verify_code:
        "Une erreur s'est produite lors de la vérification du code",
      success_to_reset_password: 'Réinitialisation réussie du mot de passe',
      error_occurred_during_reset_password:
        "Une erreur s'est produite lors de la réinitialisation du mot de passe",
      please_input_name: 'Veuillez entrer le nom',
      please_input_user_name: "Veuillez saisir le nom d'utilisateur",
      please_input_email: "Veuillez saisir l'e-mail",
      invalidate_email: 'E-mail invalidé, veuillez réessayer.',
      please_input_password: 'Veuillez entrer le mot de passe',
      please_input_country: 'Veuillez saisir le pays',
      please_input_last_name: 'Veuillez saisir le nom de famille',
      failed_to_get_user_profile_by_id:
        "Échec de l'obtention du profil utilisateur par identifiant",
      failed_to_get_one_post_by_id:
        "Échec de l'obtention d'un message par identifiant",
      failed_to_get_all_posts_on_artwork:
        'Échec de la récupération de tous les messages sur les illustrations',
      there_is_no_permission_to_edit_this_image:
        "Il n'y a pas d'autorisation pour modifier cette image.",
      failed_to_add_new_comment: "Impossible d'ajouter un nouveau commentaire.",
      failed_to_delete_post: 'Échec de la suppression du message.',
      failed_to_get_post_on_comment_screen:
        "Échec de la publication sur l'écran des commentaires",
      saved_image: 'Image enregistrée.',
      failed_to_get_openai_images: "Échec de l'obtention des images openai",
      please_input_prompt: "Veuillez saisir l'invite",
      please_do_not_use_this_word: 'Merci de ne pas utiliser ce mot',
      confirm_your_selection: 'Confirmez votre sélection.',
      please_select_image: "Veuillez sélectionner l'image.",
      saved_selected_images: 'Images sélectionnées enregistrées.',
      for_all_editing_capabilities:
        "Pour toutes les capacités d'édition, veuillez obtenir des jetons/abonnement.",
      please_select_category: 'Veuillez sélectionner la catégorie.',
      failed_to_send_invite: "Échec de l'envoi de l'invitation",
      failed_to_post: 'Échec de la publication',
      failed_to_get_users: "Échec de l'obtention des utilisateurs",
      failed_to_save_image: "Échec de l'enregistrement de l'image",
      already_added_user: 'Utilisateur déjà ajouté',
      failed_to_get_user_by_id_on_support:
        "Impossible d'obtenir l'utilisateur par identifiant sur le support",
      success_to_add_supporter: 'Succès pour ajouter un supporter.',
      failed_to_set_supporter: 'Impossible de définir un supporter',
      failed_to_delete_account: 'Échec de la suppression du compte',
      please_input_customer_name_for_payment:
        'veuillez entrer le nom du client pour le paiement.',
      your_order_is_confirmed: 'Votre commande est confirmée !',
      your_order_is_unsubscribed: 'Votre commande est désinscrite !',
      please_select_currency: 'Veuillez sélectionner Devise.',
      please_select_language: 'Veuillez sélectionner la langue.',
      please_input_fields: 'Veuillez saisir les champs.',
      please_mismatch_password: 'Veuillez ne pas correspondre au mot de passe.',
      please_input_your_name: 'Veuillez entrer votre nom.',
      please_input_subject: 'Veuillez entrer le sujet.',
      please_input_message: 'Veuillez saisir un message.',
      success_to_sent_message: 'Message envoyé avec succès.',
      failed_to_sent_message: "Échec de l'envoi du message.",
      failed_to_get_user: "Impossible d'obtenir l'utilisateur",
      creations_for_you: 'Créations, Pour vous. Par toi.',
      global_generative_ai_Platform: "Plateforme mondiale d'IA générative",
      get_started: 'Commencer',
      try_pink_elephant:
        'Essayez... Éléphant rose dansant sur un ballon dans la montagne.',
      artwork: "Ouvrages d'art",
      category: 'Catégorie',
      discover: 'Découvrir',
      search_users: 'Rechercher des utilisateurs, des images...',
      posts: 'Des postes',
      supporters: 'Partisanes',
      supporting: 'Justificative',
      bio: 'Bio',
      gallery: 'Galerie',
      collaborations: 'Collaborations',
      settings: 'Paramètres',
      tokens_left: 'JETONS RESTANTS',
      account_settings: 'Paramètres du compte',
      privacy_policy: 'Politique de confidentialité',
      terms_of_use: "Conditions d'utilisation",
      leaderboard: 'Classement',
      contact_us: 'Contactez-nous',
      logout: 'Se déconnecter',
      edit_profile: 'Editer le profil',
      change_password: 'Changer le mot de passe',
      languages: 'Langues',
      currency: 'Devise',
      delete_account: 'Supprimer le compte',
      tip_msg:
        'Lorsque vous supprimez votre compte, toutes vos données et outils de pleine conscience seront supprimés',
      are_you_sure_you_want_to_delete_your_account:
        'Êtes-vous sûr de vouloir supprimer votre compte ?',
      name: 'Nom',
      user_name: "Nom d'utilisateur",
      username: "Nom d'utilisateur",
      save_changes: 'Сохранить изменения',
      current_password: 'Mot de passe actuel',
      new_password: 'Nouveau mot de passe',
      confirm_new_password: 'Confirmer le nouveau mot de passe',
      save_new_password: 'Enregistrer le nouveau mot de passe',
      choose_languages: 'Choisissez les langues',
      select_option: "Sélectionnez l'option",
      save_language: 'Enregistrer la langue',
      choose_currency: 'Choisir la devise',
      select_currency: 'Sélectionnez la devise',
      subject: 'Sujette',
      message: 'Message',
      send: 'Envoyer',
      press_and_speak_your_prompt: 'Appuyez et énoncez votre invite !',
      edit_your_photo_further:
        'Modifiez votre photo plus loin ! Collaborez aussi !',
      image_saves_to_your_gallery_when_you_post:
        "L'image est enregistrée dans votre galerie lorsque vous publiez !",
      make_more_from_your_words_and_search_for_users_and_other_images:
        "Tirez le meilleur parti de vos mots et recherchez des utilisateurs et d'autres images !",
      upload_photos_from_your_gallery_and_edit_them:
        'Téléchargez des photos de votre galerie et modifiez-les',
      check_your_notifications_here: 'Vérifiez vos notifications ici',
      press_to_support_and_collaborate: 'Appuyez pour soutenir et collaborer',
      subscriptions: 'Подписки',
      tokens: 'Jetons',
      view_details: 'Voir les détails',
      monthly: 'Mensuelle',
      Weekly: 'Hebdomadaire',
      Yearly: 'Annuelle',
      most_popular: 'Le plus populaire',
      upgrade_plan: 'Plan de mise à niveau',
      buy_now: 'Acheter maintenant',
      plan_details: 'Détails du forfait',
      image_generator: "Générateur d'images",
      unlimited_editing_tools: "Outils d'édition illimités",
      full_collaboration_capabilities: 'Capacités de collaboration complètes',
      title: 'Titre',
      created_at: 'Créé à',
      comment: 'Commentaire',
      share_image: "Partager l'image",
      share_image_facebook: "Partager l'image facebook, Instagram, Pinterest",
      download_image: "Télécharger l'image",
      related_artwork: 'Œuvres connexes',
      no_creations_yet: 'Pas encore de créations',
      turn_off_commenting: 'Désactiver les commentaires',
      invite_collaborations: 'Inviter des collaborations',
      categories: 'Catégories',
      hide_prompt: "Masquer l'invite",
      err_phone_msg:
        "erreur : veuillez saisir l'e-mail ou le numéro de téléphone de votre ami.",
      phone_number: 'Numéro de téléphone',
      buy_more:
        'Achetez plus de jetons / obtenez un abonnement pour continuer à générer des images',
      ok: "D'ACCORD",
      generated: 'Généré',
      view_variation: 'Afficher les variantes',
      variations: 'Variantes',
      variation_msg:
        'Vous pouvez choisir parmi ces variantes ou sélectionner la génération précédente',
      new_category: 'Nouvelle catégorie',
      collaborate: 'Collaborer',
      place_search_user: 'Rechercher des utilisateurs',
      new_post: 'Nouveau poste',
      write_caption: 'Écrire la légende...',
      peoples: 'Peuples',
      access_collaboration:
        'Accédez à la collaboration en soutenant les autres et en les faisant vous soutenir !',
      artworks: 'Oeuvres',
      users: 'Utilisatrices',
      notifications: 'Avis',
      success: 'Succès',
      error_code: "Code d'erreur",
      unsubscribe: 'Se désabonner',
      connecting_appstore:
        "Connexion à l'appstore, veuillez patienter une seconde et réessayer",
      purchased: 'Achetée',
      save: 'Sauvegarder',
      only: 'Seulement',
      your_name: 'votre nom',
      liked_images: 'Images aimées',
      support: 'Soutien',
      weekly_plan_details: 'Détails du forfait hebdomadaire',
      monthly_plan_details: 'Détails du forfait mensuel',
      yearly_plan_details: 'Détails du plan annuel',
      logo_tip:
        "Conseil : utilisez le bouton du logo AIKO en bas de l'écran pour naviguer.",
      got_it: "d'accord. j'ai compris",
      instructions: 'INSTRUCTIONS',
      no_bullying: "PAS d'intimidation / harcèlement",
      no_nudity: 'PAS de nudité / contenu sexuel',
      young_visitors: '(Nous avons de jeunes visiteurs)',
      no_political: 'PAS de campagnes politiques',
      only_love: "Seuls l'amour, la création et la collaboration",
      learn_more: 'APPRENDRE ENCORE PLUS',
      login: 'Se connecter',
      welcome_back: 'Content de te revoir',
      new_to_aiko: 'Nouveau sur AIKO ?',
      sign_up: "S'inscrire",
      continue: 'Continuer',
      forgot_passwword: 'Mot de passe oublié',
      reset_password:
        'Le lien de réinitialisation du mot de passe sera envoyé à votre adresse e-mail',
      sent_verification_code: 'Nous avons envoyé le code de vérification à',
      enter_code: 'Veuillez entrer le code',
      enter_password: 'Entrer le mot de passe',
      enter_confirm_password: 'Entrez Confirmer le mot de passe',
      register: 'Registre',
      hello: 'Bonjour, faisons connaissance',
      password: 'Mot de passe',
      receive_invite: 'Avez-vous reçu une invitation de votre ami ?',
      optional: 'Facultative',
      delete: 'Supprimer',
      comments: 'commentaires',
      buy_plans: 'Acheter des forfaits',
      country: 'pays',
      more: 'plus',
      chat: 'Chat',
      chatDetail: 'Discussion privée',
      commingSoon: 'À venir !!!',
      stories: 'Histoires',
      success_to_add_story: 'Succès pour ajouter une histoire',
      saved_to_gallery: 'Enregistré dans la galerie',
      add_story: 'Ajouter une histoire',
      save_to_gallery: 'Sauvegarder dans la gallerie',
      me: 'Moi',
      viking_helmet: 'Casque viking',
      stallone: 'Stallone',
      ping_pong: 'Ping Pong',
      humanoid: 'Humanoïde',
      fire_effect: 'Effet de feu',
      emotion_meter: "Compteur d'émotions",
      emotions_exaggerator: "Exagérateur d'émotions",
      look1: 'Regardez1',
      look2: 'Regardez2',
      aviators: 'Aviateurs',
      ball_face: 'Visage de balle',
      beard: 'Barbe',
      beauty: 'Beauté',
      fairy_lights: 'Lumières féériques',
      background_segmentation: 'Segmentation en arrière-plan',
      hair_segmentation: 'Segmentation des cheveux',
      flower_crown: 'Couronne de fleurs',
      frankenstein: 'Frankenstein',
      lion: 'Lion',
      manly_face: 'Visage viril',
      plastic_ocean: 'Océan plastique',
      pumpkin: 'Citrouille',
      scuba: 'Plongeur',
      tape_face: 'Visage de bande',
      tiny_sunglasses: 'Petites lunettes de soleil',
      topology: 'Topologie',
      photo: 'photo',
      video: 'vidéo',
      create_prompt_or_upload: 'Créer une invite ou télécharger',
      or: 'ou',
      delete_story: "Supprimer l'histoire",
      failed_to_get_stories: "Impossible d'obtenir des histoires",
      success_to_delete_story: "Succès pour supprimer l'histoire",
      create_background_image_with_prompt:
        "Créer une image d'arrière-plan avec une invite",
      prompt: 'rapide',
      failed_to_get_all_supporters_by_user_id:
        "Impossible d'obtenir tous les supporters par identifiant utilisateur",
      failed_to_get_all_supportings_by_user_id:
        "Impossible d'obtenir tous les supports par identifiant utilisateur",
      failed_to_search_users: "Échec de la recherche d'utilisateurs",
      failed_to_search_posts: 'Échec de la recherche des messages',
      share_as_post: 'Partager en tant que publication',
      add_mentions: 'Ajouter des mentions',
      sell_your_work: 'Vendez votre travail',
      goods: 'Marchandise',
      price_msg: 'S’il vous plaît ajouter le prix pour cette image',
      err_price_msg: 'Erreur : veuillez saisir le prix',
      buy: 'Acheter',
      register_card: 'Carte d’enregistrement',
      input_card_information:
        'Veuillez saisir correctement les informations de la carte.',
      register_card_info: 'Enregistrer les informations de la carte',
      ar_err: 'Sera disponible bientôt',
      // 'AR prend actuellement en charge jusqu’à iOS 17.0. AR est actuellement mis à jour pour iOS 17.0 et sera bientôt disponible',
      create_story: 'Créer une histoire',
      use_ar: 'Utiliser la AR',
      use_ai_video: 'Utiliser la vidéo AI',
      for_story:
        "Pour créer une histoire, vous pouvez utiliser AR ou AI Video Generator. Choisissez en un s'il vous plait.",
      create_ai_video: 'Créer une vidéo AI',
      failed_to_generate_Video: 'Échec de la génération de la vidéo',
      use_ai_video_audio: 'Utiliser la danse IA',
      create_ai_video_audio: "Créer une vidéo à partir de l'audio",
      select_audio: 'Sélectionnez les fichiers audio',
      select_audio_desc: "Vous pouvez sélectionner jusqu'à 10 fichiers audio.",
      input_prompt: "Veuillez saisir l'invite pour créer une vidéo",
      generating_video: 'Générer des vidéos...',
      generated_video_msg:
        'Vidéos générées ajoutées sur vos histoires. Veuillez consulter les vidéos sur la page des histoires.',
      create_more_files: 'Vous ne pouvez pas générer 10 vidéos supplémentaires',
      video_audio_popup:
        'Allez faire votre truc ! Nous vous avertirons lorsque vos clips vidéo préférés seront générés !',
      create_after_msg:
        'Allez faire votre truc ! Nous vous avertirons lorsque vos clips vidéo préférés seront générés !',
      ai_audio_video_prompt_tip:
        'Una pintura de una polilla, Una pintura de una libélula asesina, Dos peces hablando entre sí en las profundidades del mar, Una pintura de una polilla,',
      audio_create_video: 'Crear vídeo',
      audio_trimming: 'clips de audio',
      choose_clip_duration: 'Elija la duración del clip',
      generator_video: 'Vídeo generador',
      second: 'Segundos',
      spotify_home: 'Spotify Hogar',
      your_playlist: 'Tus listas de reproducción',
      add_to_playlist: 'Agregar a la lista de reproducción',
      expire_token: 'El token de acceso expiró',
      search_music: 'Rechercher de la musique',
      music_name: 'Nom de la musique',
      status: 'Statut',
      completed: 'Complété',
      progressing: 'Progression',
      prompt1: 'Rapide',
      music: 'Musique',
      your_items: 'Vos articles',
      alert_uncomplete_video:
        'La vidéo est en cours de génération, veuillez vérifier après la génération.',
      success_to_update_operation: 'Ajouté aux histoires',
      success_to_delete_operation: 'Vidéo supprimée',
      add_to_story: 'Ajouter aux histoires',
      are_you_sure_you_want_to_delete_this_audio:
        'Êtes-vous sûr de vouloir supprimer cet audio ?',
      delete_video: 'Supprimer la vidéo',
      limit_downloading_cnt:
        'Vous ne pouvez pas télécharger plus de 3 fichiers en même temps.',
      musics: 'Musiques',
      are_you_sure_want_to_delete : 'Es-tu sûr! Vous souhaitez supprimer',
      database :  'Base de données',
      no_keep_it :  'Non, gardez-le',
      yes_delete_it : 'Oui, supprimez-le',
      is_invite : 'Est-ce que vous inviter à être un collaborateur sur leur publication ?',
      reject: 'Rejeter',
      accept: 'Accepter',
      my_profile : 'Mon profil',
      stripe_payment : 'Paiement Stripe',
      please_input_all_informations : 'S’il vous plaît entrer toutes les informations.',
      there_is_no_token : 'Il n’y a pas de jeton gauche. Veuillez acheter des jetons.',
      edit: 'Éditer',
      add: 'Ajouter',
      profile_picture : 'Photo de profil',
      status_complete:  'Statut : Terminé',
      status_progressing : 'Statut : En progression',
      story: 'Histoire',
      download: 'Télécharger',
      Home: 'Domicile',
      Search: 'Rechercher',
      Profile: 'Profil',
      Setting: 'Réglage',
      ContactUs: 'Contactez-nous',
      log_out: 'Se déconnecter',
      dashboard: 'Tableau de bord',
      update_profile: 'Mettre à jour le profil',
      HealthCheck: 'Bilan de santé',
      birthday: 'Anniversaire',
      Chat: 'Chatte',
    },
  },
};
